import React, { useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

const Imprint: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="">
			<Hero page="danke" />
			<>
				<div className="container max-w-[800px] mx-auto px-4  ">
					<h2 className="h2 lg:pt-12 mt-4 text-[18px] lg:text-[42px] lg:mt-6 mb-12 break-all">Impressum</h2>

					<p className="text-black mb-4">
						<strong>Radeberger Gruppe KG</strong>
						<br />
						Darmstädter Landstraße 185
						<br />
						60598 Frankfurt am Main
						<br />
					</p>
					<p className="text-black mb-4">
						Telefon: 069 - 60 65 0
						<br />
						Telefax: 069 - 60 65 209
						<br />
						E-Mail: info@radeberger-gruppe.de
						<br />
					</p>
					<p className="text-black mb-4">
						<strong>Gesamtvertretungsberechtigte Geschäftsführer:</strong>
						<br />
						Guido Mockel (Sprecher der Geschäftsführung)
						<br />
						Thomas Freese
						<br />
						Ulf Kampruwen
						<br />
						Christian Schütz
					</p>
					<p className="text-black mb-4">
						Registergericht: Frankfurt am Main
						<br />
						Registernummer: HRA 42855
					</p>
					<p className="text-black mb-4">Umsatzsteuer-Identifikationsnummer: DE814562679</p>
					<p className="text-black mb-4">Persönlich haftende Gesellschafterin: Dr. August Oetker KG, Bielefeld</p>
				</div>
			</>
			<div className="text-center pt-5 py-10">
				<Link to="/" className="btn btn-invert mt-8">
					Zurück
				</Link>
			</div>
		</div>
	)
}
export default Imprint

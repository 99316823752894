import { useContext } from "react"
import { Context } from "../../store/store"
import ElemSelect from "../elements/Select"
import ElemCheckbox from "../elements/Checkbox"
import ElemInput from "../elements/Input"
import { QUESTIONTRANSLATION } from "../../config"

interface ProductsProps {
	formElementChanged: Function
	validation: Object
}
const Product: React.FC<ProductsProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<section className="relative max-w-[1000px] mx-auto mt-20  border-t-[1px] border-t-yellow lg:mt-24  lg:pt-24 after:font-QuadroBold after:block after:content-['2'] after:text-white after:pt-[10px]  after:pl-4 after:text-[24px] after:absolute after:rounded-full after:-top-[23px]  after:left-[44%] after:lg:left-[48%] after:w-[45px] after:h-[45px] after:bg-yellow after:mx-auto">
			<h3 className="text-center text-black font-RobotoBold pb-4 mb-6 mt-24 lg:mt-6 text-[20px] uppercase xl:text-[20px]">Bevor du absendest</h3>

			<div className="grid gap-5 grid-cols-1 items-start mb-2 sm:mb-12 max-w-[750px] mx-auto">
				<ElemSelect
					label={"Welche Biermarke hast Du gekauft?"}
					props={{
						name: "question1",
						value: globalState.question1,
						required: "required",
						onChange: e => formElementChanged(e, "question1"),
					}}
					hasError={validation["invalid_question1"] ? true : false}
					className="col-span-2 md:col-span-1 text-center"
					errMsg={validation["invalid_question1_errMsg"] || "Bitte Marke auswählen.*"}
					errorMsgPadding=""
				>
					<>
						<option value=""> Welche Biermarke hast Du gekauft?*</option>
						<option value="a">Radeberger</option>
						<option value="b">Schöfferhofer</option>
						<option value="c">Jever</option>
						<option value="d">Oberdorfer Helles</option>
						<option value="e">Allgäuer Büble Bier</option>
						<option value="f">Binding</option>
						<option value="g">Brinkhoff's</option>
						<option value="h">Berliner Kindl</option>
						<option value="i">Stuttgarter Hofbräu</option>
						<option value="j">Ur-Krostitzer</option>
						<option value="k">Berliner Pilsener</option>
					</>
				</ElemSelect>
				<ElemSelect
					label={"Wo hast Du Dein Bier gekauft?"}
					props={{
						name: "question2",
						value: globalState.question2,
						onChange: e => formElementChanged(e, "question2"),
					}}
					hasError={validation["invalid_question2"] ? true : false}
					className="col-span-2 md:col-span-1 text-center"
					errMsg={validation["invalid_question2_errMsg"] || "Bitte beantworte die Frage."}
					errorMsgPadding="mt-10 2sm:mt-4 lg:mt-0 "
				>
					<>
						<option value="">Wo hast Du Dein Bier gekauft?</option>
						<option value="a">Rewe</option>
						<option value="b">Edeka</option>
						<option value="c">Tegut</option>
						<option value="d">Famila</option>
						<option value="e">Kaufland</option>
						<option value="f">Getränkefachmarkt</option>
						<option value="g">Discounter (Aldi, Lidl, Netto, Penny, Norma, etc.)</option>
						<option value="h">Sonstige</option>
					</>
				</ElemSelect>
				<ElemSelect
					label={"Wie oft hast Du Deine Biermarke in den letzten 12 Monaten gekauft?"}
					props={{
						name: "question3",
						value: globalState.question3,
						required: "required",
						onChange: e => formElementChanged(e, "question3"),
					}}
					hasError={validation["invalid_question3"] ? true : false}
					className="col-span-2 md:col-span-1 mb-2 text-center"
					errMsg={validation["invalid_question3_errMsg"] || "Bitte beantworte die Frage."}
					errorMsgPadding="mt-10 2sm:mt-4 lg:mt-0"
				>
					<>
						<option value="">Wie oft hast Du Deine Biermarke in den letzten 12 Monaten gekauft?</option>
						<option value="a">Ich habe {globalState.question1 ? QUESTIONTRANSLATION[globalState.question1] : "die Marke "} erstmals wegen der Aktion gekauft</option>
						<option value="b">
							Ich habe {globalState.question1 ? QUESTIONTRANSLATION[globalState.question1] : "die Marke "} im letzten Jahr nicht gekauft, aber vorher schon getrunken
						</option>
						<option value="c">Ich habe {globalState.question1 ? QUESTIONTRANSLATION[globalState.question1] : "die Marke "} 1x im letzten Jahr gekauft</option>
						<option value="d">Ich habe {globalState.question1 ? QUESTIONTRANSLATION[globalState.question1] : "die Marke "} 2-4x im letzten Jahr gekauft</option>
						<option value="e">Ich habe {globalState.question1 ? QUESTIONTRANSLATION[globalState.question1] : "die Marke "} mehr als 4x im letzten Jahr gekauft</option>
					</>
				</ElemSelect>
			</div>
		</section>
	)
}

export default Product

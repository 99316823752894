import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ERRORTYPES } from "../../config"
import { Context } from "../../store/store"
import Alert from "../elements/Alert"
import ElemSelect from "../elements/Select"
import Spinner from "../elements/Spinner"
import Receipt from "../receipt/Receipt"
import Legal from "./Legal"
import { placeOrder } from "./OrderFct"
import ParticipationChoose from "./ParticipationChoose"
import Person from "./Person"
import Product from "./Product"
import Scratchcard from "./ScratchCard"
import ScratchCardProduct from "./ScratchCardProduct"
const Form = () => {
	const { globalState, dispatch } = useContext(Context)
	const [validation, setValidation] = useState({})
	const navigate = useNavigate()
	const [formError, setFormError] = useState<ERRORTYPES | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "customClick", link: label })
		} catch (e) {}
	}

	const resetParticipationState = () => {
		dispatch({
			type: "RESET",
		})
	}

	const ageCheck = timeValue => {
		if (timeValue) {
			let date = new Date(timeValue.split(" ")[0])
			let now = new Date()

			if (date.getFullYear() <= 1900) {
				return true
			}

			const eighteenYears = (567648000 + 432000) * 1000 // 18 Jahre - 5 Tage // ??? schaltjahre oder so

			if (now.getTime() - date.getTime() > eighteenYears) {
				return false
			} else {
				return true
			}
		} else {
			return true
		}
	}

	function formElementChanged(e, key) {
		// special handling for checkboxes
		if (e.target.type === "checkbox") {
			e.target.value = e.target.checked
		}

		dispatch({
			type: "SET_ORDER_ENTRY",
			key: key,
			value: e.target.value
				.replace(/(<([^>]+)>)/gi, "")
				.replace("https://www", "")
				.replace("mailto:", ""),
		}) // strip HTML tags
		let isFieldInvalid = false
		if (key === "age") {
			isFieldInvalid = ageCheck(e.target.value)
		}

		const doc: any = document
		const elem = doc.querySelector("[name='" + key + "']:valid")
		if (elem === null) {
			isFieldInvalid = true
		}
		// check validation
		setValidation({ ...validation, ["invalid_" + key]: isFieldInvalid })
	}

	useEffect(() => {
		resetParticipationState()
	}, [])
	function submitOrder() {
		// create order part
		const orderSuccessCallback = (extId, price, res) => {
			if (extId) {
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "particpate", "form-submit": false, field_error: "send" })
				} catch (e) {}

				if (res.data.raffledPrizeCode === 0 && res.data.raffledPrizeName === "BLANK") {
					navigate("/schade")
				} else if (res.data.raffledPrizeCode > 0 && res.data.raffledPrizeName !== "BLANK") {
					navigate("/gewonnen", { state: { price: res.data.raffledPrizeCode, hash: res.data.externalIdentifier, isReceipt: res.data.receiptUploaded } })
				}
			} else {
				setFormError(ERRORTYPES.SERVER)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
				} catch (e) {}
			}
		}
		const orderErrCallback = (tooManyAttemps: false, invalidCaptcha: false, noCodeOrReceipt: false, raffleHasEnded: false) => {
			setIsLoading(false)

			//TODO: Handling needs to be switched to error codes (BE part still open)
			if (tooManyAttemps) {
				setFormError(ERRORTYPES.MAXTRIES)
			} else if (invalidCaptcha) {
				setValidation({ ...validation, invalid_captcha: true })
				setFormError(null)
				scrollToError()
			} else if (noCodeOrReceipt) {
				setFormError(ERRORTYPES.UPLOADERROR)
			} else if (raffleHasEnded) {
				setFormError(ERRORTYPES.RAFFLEENDED)
			} else {
				setFormError(ERRORTYPES.SERVER)
			}
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
			} catch (e) {}
		}

		if (allInputFieldsValid()) {
			if (globalState.receiptReference || globalState.code !== null) {
				setIsLoading(true)
				setFormError(null)
				placeOrder(globalState, setFormValidation, orderSuccessCallback, orderErrCallback)
			} else {
				setFormError(ERRORTYPES.UPLOADSMISSINGRECEIPT)
			}
		} else {
			setFormError(ERRORTYPES.FORMVALIDATION)
			scrollToError()
		}
	}

	function isReceiptAndFotoGiven() {
		if (globalState.receiptReference && globalState.productImg) {
			return true
		}
		return false
	}

	function isBicRequired() {
		return globalState.iban && globalState.iban.length > 2 && globalState.iban.substring(0, 2).toUpperCase() !== "DE"
	}

	function isCodeFormular() {
		return globalState.participationState !== "receipt"
	}

	function allInputFieldsValid() {
		// input names has to fit to variable classNames
		let isAllValid = true
		let invalidFields = {}

		// Special handing for bic. Bic is only for non DE IBANs required
		let fieldsToCheck: any = []
		if (isCodeFormular()) {
			fieldsToCheck = Object.keys(globalState).filter(
				e =>
					e !== "salutation" &&
					e !== "firstname" &&
					e !== "surname" &&
					e !== "age" &&
					e !== "email" &&
					e !== "street" &&
					e !== "zip" &&
					e !== "street" &&
					e !== "housenr" &&
					e !== "city" &&
					e !== "package" &&
					e !== "captchaIdent" &&
					e !== "bic" &&
					e !== "question1" &&
					e !== "question2" &&
					e !== "question3" &&
					e !== "question3" &&
					e !== "question4" &&
					e !== "question5" &&
					e !== "receiptReference" &&
					e !== "receiptFileName" &&
					e !== "terms" &&
					e !== "participationState" &&
					e !== "merchant" &&
					e !== "code" &&
					e !== "zip" &&
					e !== "street" &&
					e !== "housenr" &&
					e !== "city" &&
					e !== "optInMarketing" &&
					e !== "optInSurvey" &&
					e !== "testwinningprice" &&
					e !== "shirtsize" &&
					e !== "soccertablelogo"
			)
		} else {
			fieldsToCheck = Object.keys(globalState).filter(
				e =>
					e !== "package" &&
					e !== "captchaIdent" &&
					e !== "bic" &&
					e !== "question3" &&
					e !== "question4" &&
					e !== "question5" &&
					e !== "receiptReference" &&
					e !== "receiptFileName" &&
					e !== "terms" &&
					e !== "participationState" &&
					e !== "merchant" &&
					e !== "code" &&
					e !== "zip" &&
					e !== "street" &&
					e !== "housenr" &&
					e !== "city" &&
					e !== "testwinningprice" &&
					e !== "shirtsize" &&
					e !== "optInMarketing" &&
					e !== "optInSurvey" &&
					e !== "soccertablelogo"
			)
		}

		for (let index = 0; index < fieldsToCheck.length; index++) {
			const key = fieldsToCheck[index]
			const doc: any = document
			const elem = doc.querySelector("[name='" + key + "']:valid")
			if (elem === null) {
				isAllValid = false
				invalidFields["invalid_" + key] = true
			}
		}

		if (Object.keys(invalidFields).length) {
			setValidation({ ...validation, ...invalidFields })
		}

		return isAllValid
	}

	function setFormValidation(data: {}) {
		setValidation({
			...validation,
			...data,
		})
		scrollToError()
	}

	function scrollToError() {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-formerror").getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY - 120,
					left: 0,
					behavior: "smooth",
				})
			} catch (error) {}
		}, 500)
	}
	return (
		<>
			<ParticipationChoose />
			<form
				onSubmit={e => {
					e.preventDefault()
					submitOrder()
				}}
				noValidate={true}
				className=" max-w-[1400px] mx-auto bg-white"
			>
				<section className="">
					<div className={"px-4"}>
						{globalState.participationState === "receipt" || globalState.participationState === "" ? (
							<Receipt {...{ validation, formElementChanged }} />
						) : (
							<Scratchcard {...{ validation, formElementChanged }} />
						)}

						{globalState.participationState === "receipt" || globalState.participationState === "" ? (
							<>
								<Person {...{ validation, formElementChanged }} />
								<Product {...{ validation, formElementChanged }} />
								<Legal {...{ validation, formElementChanged }} />
							</>
						) : (
							<ScratchCardProduct {...{ validation, formElementChanged }} />
						)}

						<div className="max-w-[750px] mx-auto pl-12 mt-12 ">
							<p className="text-left  text-[12px] lg:text-[12px] font-Quicksand">* Pflichtfelder</p>
						</div>
						{globalState.participationState === "receipt" && globalState.optInMarketing == "true" && (
							<div className="max-w-[750px] mx-auto pl-12 mt-2">
								<div className="text-left  font-Quicksand">
									<p className=" text-[12px] lg:text-[12px]">** Informationen zum Newsletter</p>
									<p className=" text-[12px] lg:text-[12px]">
										Mit Klick auf „Newsletter abonnieren“ willige ich ein, den Newsletter von der ausgewählten Marke zu erhalten. Ich willige ein, dass meine personenbezogenen
										Daten, wie im Datenschutz beschrieben, verarbeitet werden.
									</p>
								</div>
							</div>
						)}
					</div>

					{/* {process.env.NODE_ENV === "development" && <>{JSON.stringify(globalState).replaceAll(",", ", ")}</>} */}
					<div className="mx-auto max-w-[840px] mb-12 lg:px-0">{formError && <Alert className={"my-4"}>{formError}</Alert>}</div>
					<div className=" grid grid-cols-1  gap-2 mx-auto max-w-[800px] mb-12 px-12 lg:px-0">
						<button type="submit" className="btn" disabled={isLoading ? true : undefined} onClick={() => track("Jetzt teilnehmen")}>
							{isLoading ? <Spinner /> : "Jetzt teilnehmen"}
						</button>
						<p
							className="text-[14px] text-yellow max-w-full mt-4 lg:max-w-[100%] text-center lg:text-left mx-auto
					"
						>
							Mit dem Anklicken der Schaltfläche „Jetzt teilnehmen“ bestätige ich, dass ich mindestens 18 Jahre alt bin und die Teilnahmebedingungen sowie die
							Datenschutzinformation zur Kenntnis genommen habe.
						</p>
					</div>
				</section>
			</form>
		</>
	)
}

export default Form

import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

const Contact: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="">
			<Hero page="danke" />
			<div>
				<div className="container max-w-[400px] mx-auto px-4 text-center">
					<h2 className="h2 lg:pt-12 mt-4 sm:text-[18px] lg:text-[42px] lg:mt-6 lg:mb-12 break-all">Kontakt</h2>

					<p className="text-black mb-4">
						<br />
						Du hast Fragen zur Aktion?
						<br />
						Dann nimm gerne Kontakt mit uns auf.
						<br />
						Wir freuen uns über Deine Nachricht!
						<br />
						<br />
						<a className="underline" href="tel:0696610236678">
							069-6610236678
						</a>
						<br />
						(montags bis freitags von 8:30 - 17:30 Uhr)
					</p>
					<p className="text-black  mb-4">
						<svg className="w-5 inline-block mr-2" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="EmailIcon">
							<path className="fill-black" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z" />
						</svg>
						<a
							href="mailto:info@deinbier-deinturnier.de"
							className="underline"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "contact", link: "mail" })
								} catch (e) {}
							}}
						>
							info@deinbier-deinturnier.de
						</a>
					</p>
					<p className="text-black mb-4">
						Allgemeine Informationen zur Aktion findest Du außerdem in den{" "}
						<Link
							to="/faq"
							target={"_blank"}
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "contact", link: "faq" })
								} catch (e) {}
							}}
						>
							<span className="underline">FAQ</span>
						</Link>
						.
					</p>
				</div>
			</div>
			<div className="text-center pt-5 py-10">
				<Link to="/" className="btn btn-invert mt-8">
					Zurück
				</Link>
			</div>
		</div>
	)
}
export default Contact

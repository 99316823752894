import { useEffect, useState, useContext } from "react"
import { Link, useSearchParams, useNavigate } from "react-router-dom"
import axios from "axios"
import { getEnvConfig } from "../env"
import { ERRORTYPES } from "../config"
import Hero from "../components/home/Hero"
import Brands from "../components/Brands"
import ElemInput from "../components/elements/Input"
import { Context } from "../store/store"
import { placeWinnerAdress } from "../components/Forms/WinnerSuccessFct"
import { globalAgent } from "http"

function WinnerAdressForm() {
	const env = getEnvConfig()
	const { globalState, dispatch } = useContext(Context)
	const [validation, setValidation] = useState({})
	const navigate = useNavigate()
	const [formError, setFormError] = useState<ERRORTYPES | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [searchParams] = useSearchParams()
	const [hashStateSet, setHashStateSet] = useState(false)
	const confirmationid = searchParams.get("hash")

	const checkWinnerAuthentification = () => {
		if (confirmationid !== null) {
			axios
				.post(env.apibase + "confirmWinnerAdress.php?hash=" + confirmationid)
				.then(res => {
					setHashStateSet(false)
				})
				.catch(error => {
					if (error.response.data.code === 406 || error.response.data.code === 404) {
						setHashStateSet(true)
					}
				})
		} else {
			setHashStateSet(true)
		}
	}

	function formElementChanged(e, key) {
		// special handling for checkboxes
		dispatch({
			type: "SET_ORDER_ENTRY",
			key: key,
			value: e.target.value
				.replace(/(<([^>]+)>)/gi, "")
				.replace("https://www", "")
				.replace("mailto:", ""),
		}) // strip HTML tags
		let isFieldInvalid = false

		const doc: any = document
		const elem = doc.querySelector("[name='" + key + "']:valid")
		if (elem === null) {
			isFieldInvalid = true
		}
		// check validation
		setValidation({ ...validation, ["invalid_" + key]: isFieldInvalid })
	}

	function submitOrder() {
		// create order part
		const orderSuccessCallback = extId => {
			if (extId) {
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "particpate", "form-submit": false, field_error: "send" })
				} catch (e) {}
				navigate("/gewonnen")
			} else {
				setFormError(ERRORTYPES.SERVER)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
				} catch (e) {}
			}
		}
		const orderErrCallback = (tooManyAttemps: false, invalidCaptcha: false) => {
			setIsLoading(false)

			//TODO: Handling needs to be switched to error codes (BE part still open)
			if (tooManyAttemps) {
				setFormError(ERRORTYPES.MAXTRIES)
			} else if (invalidCaptcha) {
				setValidation({ ...validation, invalid_captcha: true })
				setFormError(null)
				scrollToError()
			} else {
				setFormError(ERRORTYPES.SERVER)
			}
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
			} catch (e) {}
		}

		if (allInputFieldsValid()) {
			if (globalState.receiptReference || globalState.code !== null) {
				setIsLoading(true)
				setFormError(null)
				placeWinnerAdress(globalState, confirmationid, setFormValidation, orderSuccessCallback, orderErrCallback)
			} else {
				setFormError(ERRORTYPES.UPLOADSMISSINGRECEIPT)
			}
		} else {
			setFormError(ERRORTYPES.FORMVALIDATION)
			scrollToError()
		}
	}

	function isReceiptAndFotoGiven() {
		if (globalState.receiptReference && globalState.productImg) {
			return true
		}
		return false
	}

	function isBicRequired() {
		return globalState.iban && globalState.iban.length > 2 && globalState.iban.substring(0, 2).toUpperCase() !== "DE"
	}

	function allInputFieldsValid() {
		// input names has to fit to variable classNames
		let isAllValid = true
		let invalidFields = {}

		// Special handing for bic. Bic is only for non DE IBANs required
		let fieldsToCheck: any = []
		fieldsToCheck = Object.keys(globalState).filter(e => e == "street" || e == "zip" || e == "housenr" || e == "city")

		for (let index = 0; index < fieldsToCheck.length; index++) {
			const key = fieldsToCheck[index]
			const doc: any = document
			const elem = doc.querySelector("[name='" + key + "']:valid")
			if (elem === null) {
				isAllValid = false
				invalidFields["invalid_" + key] = true
			}
		}
		if (Object.keys(invalidFields).length) {
			setValidation({ ...validation, ...invalidFields })
		}

		return isAllValid
	}

	function setFormValidation(data: {}) {
		setValidation({
			...validation,
			...data,
		})
		scrollToError()
	}

	function scrollToError() {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-formerror").getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY - 120,
					left: 0,
					behavior: "smooth",
				})
			} catch (error) {}
		}, 500)
	}

	function scrollTop() {
		console.log("asdasd")
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}
	useEffect(() => {
		checkWinnerAuthentification()
	}, [])

	return (
		<>
			<Hero page="winner" />
			<div className="text-center relative z-0 -mt-[1px]  px-4 pt-6 lg:pt-12 pb-24 max-w-[900px] mx-auto">
				{!hashStateSet ? (
					<>
						<h2 className="h2 mt-12 lg:mt-24 lg:leading-[52px] mb-12">
							Herzlichen Glückwunsch <br /> du hast gewonnen!
						</h2>
						<p className=" mb-12 ">
							Damit wir Deinen Gewinn auch schnellstmöglich zu dir schicken können, gib uns bitte noch Deine Adresse. Wir informieren dich sobald Dein Gewinn zu dir auf dem Weg ist
							{". "}
						</p>
						<form
							onSubmit={e => {
								e.preventDefault()
								submitOrder()
								scrollTop()
							}}
							noValidate={true}
							className=" max-w-[500px] mx-auto bg-white"
						>
							<ElemInput
								label={"Straße*"}
								props={{
									type: "text",
									name: "street",
									value: globalState.street,
									required: "required",
									minLength: 1,
									placeholder: "Straße*",
									onChange: e => formElementChanged(e, "street"),
								}}
								hasError={validation["invalid_street"] ? true : false}
								className="col-span-2 md:col-span-1"
								errMsg={validation["invalid_street_errMsg"] || "Bitte gib Deinen Straße an."}
							/>
							<ElemInput
								label={"PLZ*"}
								props={{
									type: "text",
									name: "zip",
									value: globalState.zip,
									required: "required",
									minLength: 5,
									placeholder: "PLZ*",
									onChange: e => formElementChanged(e, "zip"),
								}}
								hasError={validation["invalid_zip"] ? true : false}
								className="col-span-2 md:col-span-1"
								errMsg={validation["invalid_zip_errMsg"] || "Bitte gib Deine PLZ an (min 5 Zeichen)."}
							/>
							<ElemInput
								label={"Housenr*"}
								props={{
									type: "text",
									name: "housenr",
									value: globalState.housenr,
									required: "required",
									minLength: 1,
									placeholder: "Hausnummer*",
									onChange: e => formElementChanged(e, "housenr"),
								}}
								hasError={validation["invalid_housenr"] ? true : false}
								className="col-span-2 md:col-span-1"
								errMsg={validation["invalid_housnr_rrMsg"] || "Bitte gib Deine Hausnummer an."}
							/>
							<ElemInput
								label={"Stadt*"}
								props={{
									type: "text",
									name: "city",
									value: globalState.city,
									required: "required",
									minLength: 1,
									placeholder: "Stadt*",
									onChange: e => formElementChanged(e, "city"),
								}}
								hasError={validation["invalid_city"] ? true : false}
								className="col-span-2 md:col-span-1"
								errMsg={validation["invalid_city_errMsg"] || "Bitte gib Deine Stadt an."}
							/>
							<button type="submit" className="btn mt-6" disabled={isLoading ? true : undefined}>
								Jetzt teilnehmen
							</button>
						</form>
					</>
				) : (
					<>
						<h2 className="h2 mt-12 lg:leading-[52px] mb-12">Sorry, hier scheint etwas nicht zu stimmen.</h2>
						<p className=" mb-12 ">Dieser Link ist nicht mehr gültig. Entweder gehörst du leider nicht zu den Gewinnern oder du hast bereits Deine Daten an uns übermittelt.</p>
						<div className="text-center pt-5 py-10">
							<Link to="/" className="btn btn-invert mt-8">
								Zurück zum Gewinnspiel
							</Link>
						</div>
					</>
				)}
			</div>

			<Brands />
		</>
	)
}
export default WinnerAdressForm

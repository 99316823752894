import { useAutoAnimate } from "@formkit/auto-animate/react"
import axios from "axios"
import { useState } from "react"
import Dropzone from "react-dropzone"
import { ERRORTYPES } from "../../config"
import { getEnvConfig } from "../../env"
import convertPDF2JPG from "../convertPDF/PDF2JPG"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"

interface ReceiptUploadProps {
	dispatch: Function
}
const ReceiptUpload: React.FC<ReceiptUploadProps> = ({ dispatch }) => {
	const env = getEnvConfig()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<ERRORTYPES | null>()
	const [acceptedFile, setAcceptedFile] = useState<any>(null)

	const [receiptUploadRef] = useAutoAnimate<HTMLDivElement>()

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "customClick", link: label })
		} catch (e) {}
	}

	function onDrop(dropedFiles: any) {
		setLoading(true)
		if (dropedFiles.length > 0) {
			if (dropedFiles[0].size > 8000000) {
				// 8MB
				setError(ERRORTYPES.FILESIZE)
				setLoading(false)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
				} catch (e) {}
			} else {
				setError(null)

				setAcceptedFile(dropedFiles[0]) // TODO: check if this needs to be holded in the state???
				handleFileType(dropedFiles[0])
				setLoading(true)
			}
		} else {
			setError(ERRORTYPES.FILESIZE)
			setLoading(false)
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
			} catch (e) {}
		}
	}

	function handleFileTypeError(error: any) {
		try {
			if (error.response.data.errors.errors[0] === "Receipt already used.") {
				setError(ERRORTYPES.DOUBBLED)
			} else if (error.response.data.errors.errors[0] === "Reached maximum attempts with uploaded receipt.") {
				setError(ERRORTYPES.MAXTRIES)
			} else {
				setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
			}
		} catch (error) {
			setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
		}

		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
		} catch (e) {}

		setLoading(false)
		setAcceptedFile(null)
	}

	function handleFileType(file) {
		setLoading(true)
		if (file.type !== "application/pdf") {
			uploadReceipt(file)
		} else {
			/* ✨ PDF magic ✨ */
			convertPDF2JPG(file)
				.then(file => {
					uploadReceipt(file)
				})
				.catch(error => {
					console.log("Error converting PDF to B64", error)
					setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
					setLoading(false)
					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
					} catch (e) {}
				})
		}
	}

	async function uploadReceipt(file: any) {
		const env = getEnvConfig()
		const formdata = new FormData()
		formdata.append("file", file)

		axios
			.post(env.apibase + "uploadReceipt.php?cv=" + Date.now(), formdata, {
				headers: {
					"content-type": "multipart/form-data",
					Accept: "application/json",
				},
			})
			.then(res => {
				if (res.data.enqueued) {
					checkOCRStatus(res.data.temporaryReceiptDataId)

					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "receipt", "receipt-upload": "upload-successful" })
					} catch (e) {}
				} else {
					handleFileTypeError(new Error("File submitted but something's wrong 🤔: " + JSON.stringify(res)))

					try {
						// eslint-disable-next-line
						let dataLayer = window["dataLayer"] || []
						dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
					} catch (e) {}
				}
			})
			.catch(error => {
				handleFileTypeError(error)
				setAcceptedFile(null)
			})
	}

	async function checkOCRStatus(reference: string, maxTries: number = 20) {
		axios
			.get(env.apibase + "checkReceiptUploadStatus.php?reference=" + reference + "&cv=" + Date.now())
			.then(res => {
				if (!!res.data.gptPassed) {
					// Process succeeded

					if (res.data.gptProcessed) {
						dispatch({
							type: "SET_RECEIPT_REFERENCE",
							receiptReference: reference,
							receiptFileName: reference,
						})
						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": true })
						} catch (e) {}
					} else {
						setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
						setAcceptedFile(null)
					}

					setLoading(false)
				} else {
					maxTries--
					if (maxTries === 0) {
						setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
						setLoading(false)

						console.log(new Error("Too many attempts!"))
						setAcceptedFile(null)

						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
						} catch (e) {}
					} else {
						setTimeout(() => checkOCRStatus(reference, maxTries), 2000)
					}
				}
			})
			.catch(error => {
				setError(ERRORTYPES.RECEIPT_UPLOAD_ERROR)
				setAcceptedFile(null)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "receipt", "form-submit": false, field_error: "upload_receipt" })
				} catch (e) {}
			})
	}

	return (
		<Dropzone onDrop={onDrop} multiple={false} accept="image/jpeg,image/png,application/pdf">
			{({ getRootProps, getInputProps, open }) => (
				<section ref={receiptUploadRef}>
					{!loading ? (
						<div {...getRootProps()} onClick={open}>
							<input type="file" accept="image/jpeg,image/png,application/pdf" {...getInputProps()} />
							<div className="text-center md:min-h-[90px]  grid items-center mx-auto">
								{acceptedFile ? (
									<>
										<span className="btn mx-auto mt-4">
											<strong className="text-[12px]">{acceptedFile.name}</strong>
										</span>
										<p className="mt-4 text-[12px]">Die Datei wurde erfolgreich hochgeladen.</p>
									</>
								) : (
									<div className="relative grid items-center">
										{error ? (
											<>
												<span className="btn bg-red border-red">
													<strong className="">Upload</strong>
													<img
														src={process.env.PUBLIC_URL + "/images/upload_error.png"}
														alt=""
														className="ml-2 -mt-[5px] inline-block h-[25px] w-[25px] flex-shrink-0 flex-grow-0"
													/>
												</span>
											</>
										) : (
											<>
												<a className="btn mx-auto mt-4" onClick={() => track("Datei hochladen")}>
													<strong>Datei hochladen</strong>
												</a>
											</>
										)}
									</div>
								)}
							</div>
						</div>
					) : (
						<div className="justify-center text-center mt-4 grid items-center md:min-h-[90px] mx-auto">
							<span className="btn load-effect border-white ">
								<p className="z-[1] relative w-full">Datei wird hochgeladen</p>
							</span>
						</div>
					)}
					{error && <Alert className={"text-left mt-3"}>{error}</Alert>}
				</section>
			)}
		</Dropzone>
	)
}

export default ReceiptUpload

import React from "react"

interface AccordionItemProps {
	children?: any
	headline?: string
	num?: string
}

const AccordionItem: React.FC<AccordionItemProps> = ({ children, headline, num }) => {
	return (
		<label className="block bg-white rounded-[48px] py-6 pl-[68px] pr-14 relative mb-6">
			<input type={"checkbox"} className="peer opacity-0 h-0 absolute" />
			<h4 className="h5 absolute left-6">
				<span className="text-[18px] lg:text-[22px] leading-5 text-darkgray  top-[37%]">{num}</span>
			</h4>
			<h5 className="h5 text-darkgray text-[18px] lg:text-[22px]">{headline}</h5>
			<svg className="absolute right-8 top-7 w-6 peer-checked:-rotate-180" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ExpandMoreIcon">
				<path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" className="fill-green" />
			</svg>
			<div className=" -ml-8 sm:ml-0 transition-all  max-h-0 duration-500 peer-checked:lg:max-h-[1700px] peer-checked:max-h-[2700px] peer-checked:pt-6 peer-checked:pb-5 ease-in-out overflow-x-hidden">
				{children}
			</div>
		</label>
	)
}
export default AccordionItem

import { useContext } from "react"
import { Context } from "../../store/store"
import ElemCheckbox from "../elements/Checkbox"
import ElemSelect from "../elements/Select"
import { Link } from "react-router-dom"
import { getEnvConfig } from "../../env"
import { QUESTIONTRANSLATION } from "../../config"

interface LegalProps {
	formElementChanged: Function
	validation: Object
}
const Legal: React.FC<LegalProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<div className=" max-w-[745px] mx-auto text-black">
			{QUESTIONTRANSLATION[globalState.question1] !== "Binding" &&
			QUESTIONTRANSLATION[globalState.question1] !== "Allgäuer Büble Bier" &&
			QUESTIONTRANSLATION[globalState.question1] !== "Oberdorfer Helles" ? (
				<ElemCheckbox
					label={
						<>
							Ja, ich möchte den Newsletter {globalState.question1 ? "von " + QUESTIONTRANSLATION[globalState.question1] : "der oben genannten Marke "} erhalten und über
							Neuprodukte, Gewinnspiele und Aktionen per E-Mail informiert werden.{globalState.optInMarketing == "true" ? "**" : ""}
						</>
					}
					props={{
						type: "checkbox",
						name: "optInMarketing",
						value: globalState.optInMarketing,
						onClick: e => formElementChanged(e, "optInMarketing"),
					}}
					hasError={validation["invalid_optInMarketing"] ? true : false}
					className="sm:pb-10"
					errMsg={validation["invalid_optInMarketing_errMsg"] || "Bitte Teilnahmebedingungen und Datenschutzbestimmungen bestätigen."}
					errorMsgPadding="mt-4 2sm:mt-4 pl-10 lg:pl-12"
				/>
			) : (
				""
			)}
			<ElemCheckbox
				label={<>Ja, ich gebe gerne mein Feedback zur Aktion und möchte mit einer Umfrage nach Aktionsende kontaktiert werden.</>}
				props={{
					type: "checkbox",
					name: "optInSurvey",
					value: globalState.optInSurvey,
					onClick: e => formElementChanged(e, "optInSurvey"),
				}}
				hasError={validation["invalid_optInSurvey"] ? true : false}
				className="sm:pb-10 mt-12 lg:mt-0"
				errMsg={validation["invalid_optInSurvey_errMsg"] || "Bitte Teilnahmebedingungen und Datenschutzbestimmungen bestätigen."}
				errorMsgPadding="mt-10 2sm:mt-4 lg:mt-0 pl-10 lg:pl-12"
			/>
		</div>
	)
}

export default Legal

import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

const Privacy: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-green">
			<Hero page="danke" />
			<>
				<div className="container max-w-[800px] mx-auto px-4 ">
					<h2 className="h2 lg:pt-4 mt-4 sm:text-[18px] lg:text-[32px] lg:leading-[44px] lg:mt-6 mb-6 break-all">
						Allgemeine <br />
						<p className=" mt-4 text-[14px] lg:text-[32px]">Datenschutzinformationen</p>
					</h2>
					<p className="mb-6 text-black">
						<strong>Datenschutzrechtlich Verantwortlicher:</strong>
						<br />
						Radeberger Gruppe KG <br />
						Darmstädter Landstraße 185 <br />
						60598 Frankfurt am Main <br />
						E-Mail:{" "}
						<a href="mailto:info@radeberger-gruppe.de" className="underline">
							info@radeberger-gruppe.de
						</a>{" "}
						<br />
						Website:{" "}
						<a href="https://www.radeberger-gruppe.de" className="underline" target="_blank">
							www.radeberger-gruppe.de
						</a>{" "}
					</p>
					<p className="mb-6 text-black">
						<strong>Kontaktdaten des Datenschutzbeauftragten:</strong>
						<br />
						Datenschutzbeauftragter
						<br />
						Radeberger Gruppe KG <br />
						Darmstädter Landstraße 185 <br />
						60598 Frankfurt am Main <br />
						E-Mail:{" "}
						<a href="mailto:radeberger-gruppe@corporate-data-protection.com" className="underline">
							radeberger-gruppe@corporate-data-protection.com
						</a>{" "}
					</p>
					<p className="mb-6 text-black">
						Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und erheben personenbezogene Daten daher grds. nur im notwendigen Umfang. Die nachfolgende Information
						gibt Ihnen einen Überblick darüber, welche Ihrer personenbezogenen Daten zu welchen Zwecken bei Nutzung unserer Websites oder anderen Anlässen verarbeitet werden. Wir
						halten diese Datenschutzinformation immer auf dem aktuellen Stand und passen sie erforderlichenfalls an veränderte tatsächliche oder gesetzliche Rahmenbedingungen an.
						Diese Anpassungen werden mit Nutzung unserer Websites akzeptiert.
					</p>
					<p className="mb-6 text-black">
						<strong>Datenverarbeitung auf dieser Internetseite</strong>
					</p>
					<p className="mb-6 text-black">
						1) Automatisch generierte Daten <br />
						2) Cookies <br />
						a) Beschreibung und Umfang der Datenverarbeitung <br />
						b) Cookie-Kategorien <br />
						c) Rechtsgrundlage <br />
						d) Löschung der Cookies
						<br />
						e) Besondere Cookies: Google Analytics
						<br />
						3) Gewinnspiele / Aktionen
						<br />
						4) Kontaktaufnahme
						<br />
						5) Bilder bei Veranstaltungen
						<br />
						6) Zugriff Dritter
						<br />
						7) Dauer der Datenverarbeitung
						<br />
						8) Ihre Rechte
					</p>
					<p className="mb-6 text-black">
						<strong>1) Automatisch generierte Daten</strong>
					</p>
					<p className="mb-2 text-black">
						Bei jedem Aufruf unserer Websites werden in Logfiles automatisch Daten verarbeitet, die von Ihrem Endgerät stammen und auch personenbezogene Daten umfassen können. Dies
						gilt für folgende Daten:
					</p>
					<ul className="mb-6 text-black">
						<li className="text-black text-[18px]">• Browsertyp/-version</li>
						<li className="text-black text-[18px]">• Betriebssystem</li>
						<li className="text-black text-[18px]">• Name des Providers</li>
						<li className="text-black text-[18px]">• IP-Adresse</li>
						<li className="text-black text-[18px]">• Datum und Uhrzeit des Aufrufs</li>
						<li className="text-black text-[18px]">• besuchte Websites (und etwaige Suchwörter)</li>
						<li className="text-black text-[18px]">• Referrer URL (die zuvor besuchte Seite)</li>
					</ul>

					<p className="mb-6 text-black">
						Wir verarbeiten diese Daten nicht zusammen mit anderen personenbezogenen Daten von Ihnen, d.h. wir ordnen diese Daten nicht Ihrer Person zu.
					</p>
					<p className="mb-6 text-black">
						Rechtsgrundlage für die Verarbeitung dieser Daten ist Art. 6 Abs. 1 f) DSGVO. Die Verarbeitung ist erforderlich, um die Funktionsfähigkeit der Website sicherzustellen
						sowie statistische Auswertungen vorzunehmen, um die Inhalte unserer Websites zu optimieren und korrekt darzustellen und ggf. um Strafverfolgungsbehörden z.B. im Falle
						eines Cyberangriffes die zur Strafverfolgung notwendigen Informationen bereitzustellen. Darin liegt auch das berechtigte Interesse an der Datenverarbeitung. Diese
						Datenverarbeitung ist für den Betrieb der Websites zwingend notwendig. Es besteht daher keine Möglichkeit des Widerspruchs.
					</p>
					<p className="mb-6 text-black">
						Die o.g. Daten werden gelöscht, sobald sie für die Erreichung der vorgenannten Zwecke nicht mehr erforderlich sind , d.h. wenn der jeweilige Website-Besuch beendet ist.
						Im Falle der Verarbeitung Ihrer IP-Adresse erfolgt die Löschung spätestens nach 7 Tagen.
					</p>
					<p className="mb-6 text-black">
						<strong>2) Cookies</strong>
					</p>
					<p className="mb-6 text-black">
						Nachfolgend werden die unterschiedlichen Arten von Cookies und vergleichbare Technologien beschrieben, die auf unserer Website verwendet werden.
					</p>
					<p className="mb-6 text-black">
						<strong>a) Beschreibung und Umfang der Datenverarbeitung</strong> Unsere Website verwendet Cookies. Bei Cookies handelt es sich um Textdateien, die beim ersten Besuch
						unserer Website in Ihrem Internetbrowser erzeugt und gespeichert werden. Beim nächsten Aufruf der Website mit demselben Endgerät werden die darin gespeicherten
						Nutzungsdaten entweder an die Website zurückgesandt, die sie erzeugt hat (First Party Cookie) oder an eine andere Website gesandt, zu der es gehört (Third Party
						Cookie). So erkennt die Website bei späteren Besuchen des Nutzers, ob bzw. dass sie mit diesem Browser schon einmal aufgerufen wurde. Dadurch kann die Website bei
						erneutem Aufruf insbesondere an die Bedürfnisse des Nutzers angepasst und die Nutzung der Website statistisch ausgewertet und die Darstellung der angezeigten Inhalte
						variiert werden. Der Begriff Cookies wird nachfolgend für alle Technologien verwendet, mit denen Nutzerdaten lokal gespeichert und ggf. im Verlauf Ihres Websitebesuchs
						an uns oder Drittanbieter übertragen werden.
					</p>
					<p className="mb-6 text-black">
						<strong>b) Cookie-Kategorien</strong> Die Cookies haben eine unterschiedliche Speicherdauer. Auf unserer Website werden sowohl „Permanent-Cookies“ als auch
						„Session-Cookies“ eingesetzt: <br /> <br />
						<strong>aa) Die Session-Cookies</strong> werden nur während Ihres aktuellen Besuchs auf unserer Website gespeichert und dienen dazu, Ihnen eine uneingeschränkte Nutzung
						unserer Dienste so-wie eine möglichst komfortable Nutzung unserer Internetseite für den aktuellen Besuch unserer Seite zu ermöglichen. Bei einer Deaktivierung von
						Session-Cookies kann nicht sichergestellt werden, dass Sie alle unsere Dienste ohne Einschränkung nutzen können. <br /> <br />
						<strong>bb) Die Permanent-Cookies</strong> bleiben auch nach Ihrem Besuch auf unserer Website vorübergehend lokal in Ihrem Internetbrowser gespeichert (temporäre
						Cookies) und dienen dazu, Ihnen eine möglichst komfortable Nutzung unserer Internetseite auch über den aktuellen Besuch hinaus zu ermöglichen und werden von uns nur zu
						diesem Zweck verwendet. Eine Deaktivierung dieser Cookies hat in der Regel keinen Einfluss auf die Nutzbarkeit unserer Seite. Abhängig von ihrer Funktion und ihrem
						Verwendungszweck können Cookies in die folgenden Kategorien eingeteilt werden: <br /> <br />
						<strong>aa) Notwendige Cookies (Typ 1)</strong> Diese Cookies sind zwingend erforderlich, damit unsere Website und deren Funktionen ordnungsgemäß arbeiten. Sie
						ermöglichen es, Komfort und Leistung von Websites zu verbessern und verschiedene Funktionen zur Verfügung zu stellen. Hierdurch können z.B. bereits getätigte Angaben
						(wie z.B. Benutzernamen, Sprachauswahl oder der Ort, an dem Sie sich befinden) gespeichert werden, um Ihnen erneute Eingaben zu ersparen. <br /> <br />
						<strong>bb) Funktionale Cookies (Typ 2)</strong> Diese Cookies dienen dazu, Informationen über Ihre Nutzung unserer Website zu erhalten. Sie ermöglichen z.B. die
						Identifizierung besonders populärer Bereiche unseres Internetangebots, um so die Inhalte unserer Website gezielter auf Ihre Bedürfnisse abstimmen zu können. Nähere
						Informationen zu diesen Cookies und deren individueller Löschung finden Sie unten unter e). <br /> <br />
						<strong>cc) Marketing- und Dritt-Cookies (Typ 3)</strong> Diese Cookies werden genutzt, um gezielter für den Nutzer relevante und an seine Interessen angepasste
						Werbeanzeigen auszuspielen. Diese Information kann mit Dritten, wie z.B. Werbetreibenden, geteilt werden. Cookies zur Verbesserung der Zielgruppenansprache und Werbung
						werden oftmals mit Seitenfunktionalitäten von Dritten verlinkt. Nähere Informationen zu Marketing-Cookies und deren individueller Löschung finden Sie auch unten unter
						e). Unsere Website kann auch Inhalte von Drittanbietern enthalten, wie z.B. Facebook-Dienste oder YouTube-Videos. Diese Dritten können Cookies setzen, während Sie
						unsere Website nutzen, und über diese Website-Nutzung Informationen erhalten. Die Cookies werden in erster Linie verwendet, um Social Media Inhalte wie Social Plugins
						auf unserer Seite zu integrieren. Weitere Informationen hierzu finden Sie ggf. unten sowie auf den Websites der Drittanbieter. <br /> <br />
						<strong>c) Rechtsgrundlage</strong> Die Cookies verarbeiten ausschließlich anonymisierte und pseudonymisierte Daten. Die Bereitstellung dieser Daten ist weder
						gesetzlich noch vertraglich vorgeschrieben noch für einen Vertragsabschluss erforderlich. Soweit auch personenbezogene Daten in Form von pseudonymisierten Daten
						verarbeitet werden, ist Rechtsgrundlage hierfür die von Ihnen bei Aufruf unserer Website erteilte Einwilligung (Art. 6 Abs. 1 a) DSGVO). <br /> <br />
						<strong>d) Löschung der Cookies</strong> Sie können unsere Website ggf. auch ohne die Nutzung von Cookies besuchen. Die Verhinderung der Speicherung neuer Cookies und
						die Löschung bereits gesetzter Cookies kann durch folgende Maßnahmen erreicht werden: Im Falle einer erteilten Einwilligung (Art. 6 Abs. 1 a) DSGVO) werden wir Ihre
						Daten nach Widerruf oder Wegfall des Verarbeitungszwecks für die Zukunft löschen. Den Widerruf können Sie per Brief oder per E-Mail an uns richten. Informationen zur
						selbstständigen Löschung von Cookies entnehmen Sie bitte der Anleitung Ihres Browsers bzw. Endgeräte-Herstellers. Zusätzliche Informationen zu Funktionalen Cookies und
						Marketing-Cookies (Typ 2 und Typ 3) und deren individueller Löschung gegenüber konkret benannten Dritten finden Sie im Nachfolgenden. <br /> <br />
						<strong>e) Besondere Cookies</strong> Unsere Website verwendet folgendes Funktional-Cookie: Google Analytics: Zur Verbesserung von Komfort und Qualität unseres Service
						sind auf dieser Website die nachfolgenden Webdienste der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland („Google“) aktiviert. Google erhält und
						verarbeitet die Daten, die durch Einsatz der entsprechenden Cookies auf unserer Website generiert werden. Google Analytics verwendet temporäre Cookies, die eine Analyse
						Ihrer Nutzung der Website ermöglichen. Die gespeicherten Daten über die Benutzung unserer Website einschließlich Ihrer IP-Adresse („Nutzungsdaten“) werden in der Regel
						an einen Server von Google in die USA übertragen und dort gespeichert. Wir weisen darauf hin, dass auf unserer Website Google Analytics um den Code
						„gat._anonymizeIp();;“ erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten. Durch die Aktivierung der IP-Anonymisierung
						auf unserer Website wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
						Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Google
						wird die genannten Informationen verwenden, um Ihre Nutzung unserer Website auszuwerten, um Reports über die Websiteaktivitäten für uns zusammenzustellen und um weitere
						mit der Nutzung der Website und des Internets verbundene Dienstleistungen für uns zu erbringen. Eine Übertragung dieser Daten durch Google an Dritte findet nur aufgrund
						gesetzlicher Vorschriften oder im Rahmen der Auftragsverarbeitung statt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
						Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Website vollumfänglich werden
						nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung dieser Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
						Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link www.google.com/settings/ads/plugin verfügbare Browser-Plugin
						herunterladen und installieren. Nähere Informationen zu Google Analytics finden Sie unter diesem Link: https://tools.google.com/dlpage/gaoptout?hl=de.
					</p>
					<p className="mb-6 text-black">
						<strong>3) Gewinnspiele / Aktionen</strong>
					</p>
					<p className="mb-6 text-black">
						Wenn Sie an einem von uns veranstalteten Gewinnspiel oder ähnlichen Aktionen teilnehmen, verarbeiten wir Ihre dabei mitgeteilten personenbezogenen Daten zum Zweck der
						Durchführung der Aktion sowie zu deren Dokumentation etc. Eine Weitergabe an Dritte erfolgt grds. nur, wenn dies zur Bearbeitung bzw. Durchführung erforderlich ist
						(z.B. an Dienstleister für die Versendung der Gewinne o.ä.). Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 b) und f) DSGVO. Nähere Informationen zur
						Datenverarbeitung bei Gewinnspielen und ähnlichen Aktionen erhalten Sie zudem in den Teilnahmebedingungen der jeweiligen Aktion.
					</p>
					<p className="mb-6 text-black">
						<strong>4) Kontaktaufnahme</strong>
					</p>
					<p className="mb-6 text-black">
						Wenn Sie uns per E-Mail, Social Media, Kontaktformular oder auf andere Weise kontaktieren (z.B. im Rahmen einer Anfrage, Reklamation o.ä.), verarbeiten wir Ihre dabei
						angegebenen personenbezogenen Daten. Die Verarbeitung erfolgt dann zum Zweck der Bearbeitung der Anfrage/Reklamation o.ä. sowie zu deren Dokumentation. Eine Weitergabe
						an Dritte erfolgt nur, wenn dies zur Bearbeitung bzw. Durchführung erforderlich ist (z.B. an den Veranstalter bei Anmeldungen zu einer Veranstaltung). Rechtsgrundlage
						für die Datenverarbeitung ist Art. 6 Abs. 1 a), b) und f) DSGVO.
					</p>
					<p className="mb-6 text-black">
						<strong>5) Bilder bei Veranstaltungen</strong>
					</p>
					<p className="mb-6 text-black">
						Wir lassen bei unseren Veranstaltungen Fotos/Videos („Bilder“) von u.a. von Besuchern anfertigen. Hierbei werden die Personen entweder individuell oder als Teil einer
						Gruppe abgebildet. Die Bilder werden zu Berichts- und Dokumentationszwecken sowie zu Kommunikations- und Werbezwecken im Zusammenhang mit der Veranstaltung genutzt und
						veröffentlicht, insbesondere auf der Marken-Website und der Facebook-Seite der beteiligten Marke sowie ggf. auf der Unternehmenswebsite www.radeberger-gruppe.de und im
						Mitarbeiter- und Kundenmagazin. Die Bilder werden bei uns archiviert. Rechtsgrundlage ist §§ 22, 23 KUG sowie Art. 6 Abs. 1 a und f) DSGVO.
					</p>
					<p className="mb-6 text-black">
						<strong>6) Zugriff Dritter</strong>
					</p>
					<p className="mb-6 text-black">
						Die Datenverarbeitung erfolgt nur durch uns und ggf. durch von uns beauftragte Dienstleister sowie im Falle Ihrer Einwilligung oder im Rahmen von Vertragsverhältnissen
						auch durch sonstige Dritte und im Falle von pseudonymisierten Daten auch durch Unternehmen der Oetker Gruppe, mit denen wir konzernmäßig verbunden sind. Im Übrigen
						werden wir Ihre personenbezogenen Daten nur bei behördlicher oder gesetzlicher Verpflichtung verarbeiten.
					</p>
					<p className="mb-6 text-black">
						<strong>7) Dauer der Datenverarbeitung</strong>
					</p>
					<p className="mb-6 text-black">
						Ihre personenbezogenen Daten verarbeiten wir nur, solange die für die jeweilige Verarbeitung genannten Zwecke i.S.d. Art. 6 Abs. 1 DSGVO vorliegen bzw. bis zum Ablauf
						der Verjährungsfristen etwaiger Ansprüche und gesetzlicher Aufbewahrungspflichten.
					</p>
					<p className="mb-6 text-black">
						<strong>8) Ihre Rechte</strong>
					</p>
					<p className="mb-6 text-black">
						Sie haben jederzeit das Recht auf Auskunft über die bezüglich Ihrer Person gespeicherten Daten sowie auf Berichtigung, Löschung, Einschränkung der Verarbeitung,
						Datenübertragbarkeit und Widerspruch (Art. 15-21 DSGVO), ggf. auf Widerruf einer erteilten Einwilligung (mit Wirkung für die Zukunft, Art. 7 DSGVO) sowie auf Beschwerde
						bei einer Aufsichtsbehörde (Art. 77 DSGVO). Wenn Sie Ihre Rechte geltend machen wollen oder Fragen haben, die Ihnen diese Datenschutzinformation nicht beantworten
						konnte, wenden Sie sich bitte per Brief oder E-Mail an unseren Datenschutzbeauftragten (Kontaktdaten siehe oben).
					</p>
					<p className="mb-6 text-black">
						<strong>9) Newsletter</strong>
					</p>
					<p className="mb-6 text-black">
						Du kannst dich für den Bezug von E-Mail-Newslettern auf unseren Websites registrieren. Dazu erfassen wir die von dir in der Registrierung angegebenen Daten und
						übermitteln dir zur Authentifizierung einen Bestätigungslink an die angegebene E-Mail-Adresse. Erst mit der Aktivierung des Bestätigungslinks ist die Registrierung
						abgeschlossen. Anderenfalls, d. h. spätestens nach 72 Stunden löschen wir deine uns überlassenen Daten wieder.
					</p>
					<p className="mb-6 text-black">
						Im Fall der Registrierung verarbeiten wir deine personenbezogenen Daten zwecks Versand der Newsletter. Mit den Newslettern informieren wir dich alle 4-8 Wochen und ggf.
						zu Sonderanlässen über unsere Marken und damit zusammenhängende Neuigkeiten, Produktinformationen, Gewinnspiele, Aktionen, sofern sich aus der Newsletter-Beschreibung
						in der Registrierungsmaske nichts anderes ergibt. Die von dir eingegebenen Daten (bspw. Anrede, Name, Geburtsdatum oder PLZ) analysieren und nutzen wir, um dir
						Newsletterinformationen gezielt und individualisiert bereitstellen zu können.
					</p>
					<p className="mb-6 text-black">
						Rechtsgrundlage für die Datenverarbeitung ist deine Einwilligung (Art. 6 Abs. 1 a) DSGVO). Du kannst diese, etwa über den Abmeldelink am Ende eines jeden Newsletters,
						mit Wirkung für die Zukunft jederzeit widerrufen.
					</p>
				</div>
			</>
			<div className="text-center pt-5 py-10">
				<button
					onClick={() => {
						navigate(-1)

						try {
							// eslint-disable-next-line
							let dataLayer = window["dataLayer"] || []
							dataLayer.push({ event: "privacy", link: "zurueck" })
						} catch (e) {}
					}}
					className="btn  mt-8"
				>
					Zurück
				</button>
			</div>
		</div>
	)
}
export default Privacy

import { Link } from "react-router-dom"
function track(label: string) {
	try {
		// eslint-disable-next-line
		let dataLayer = window["dataLayer"] || []
		dataLayer.push({ event: "customClick", link: label })
	} catch (e) {}
}

const MobileMenu = ({ isPromoOver, setShowMenu, scrollToAnchor }) => {
	return (
		<div className=" fixed lg:absolute top-0  w-[100vw] h-[100vh] lg:h-[560px] z-[300] bg-bggray text-center items-center max-w-[1400px] mx-auto ">
			<div className="flex align-middle items-center lg:items-start relative h-[100vh]  pt-20 lg:pt-0">
				<img
					src={process.env.PUBLIC_URL + "/images/icon-close.svg"}
					alt="Menu"
					loading="lazy"
					width="83"
					height="73"
					className=" w-[40px] cursor-pointer absolute left-8 top-8"
					onClick={() => setShowMenu(false)}
				/>
				<ul className="mx-auto w-full">
					{!isPromoOver && (
						<>
							<li className="font-QuadroBold">
								<Link
									to="/"
									onClick={() => {
										scrollToAnchor("")
										setShowMenu(false)
										track("Startseite")
										try {
											// eslint-disable-next-line
											let dataLayer = window["dataLayer"] || []
											dataLayer.push({ event: "header", link: "sogehts" })
										} catch (e) {}
									}}
									className="header-item block text-black w-full text-center font-QuadroBold border-t-[1px] lg:border-t-0  border-white  py-[40px] lg:py-[45px] m-0"
								>
									Startseite
								</Link>
							</li>
							<li className=" font-QuadroBold">
								<Link
									to="/"
									onClick={() => {
										scrollToAnchor("sogehts")
										setShowMenu(false)
										track("So gehts")
									}}
									className="header-item block text-black w-full text-center font-QuadroBold border-t-[1px] lg:border-t-1 border-b-[1px] border-white  py-[40px] lg:py-[45px]  m-0"
								>
									So geht's
								</Link>
							</li>
						</>
					)}
					<li className=" font-QuadroBold">
						<Link
							to={process.env.PUBLIC_URL + "/teilnahmebedingungen"}
							className="header-item block text-black w-full text-center font-QuadroBold border-b-[1px] border-white py-[40px] lg:py-[45px]"
							onClick={() => {
								track("/teilnahmebedingungen(subpage)")
								return setShowMenu(false)
							}}
						>
							Teilnahmebedingungen
						</Link>
					</li>
					<li className="font-QuadroBold">
						<Link
							to={process.env.PUBLIC_URL + "/faq"}
							className="header-item block text-black w-full text-center font-QuadroBold border-b-[1px] border-white py-[40px] lg:py-[40px]"
							onClick={() => {
								track("/faq(subpage)")
								return setShowMenu(false)
							}}
						>
							FAQ
						</Link>
					</li>
					<li className="">
						<Link
							to={process.env.PUBLIC_URL + "/kontakt"}
							className="header-item block text-black w-full text-center font-QuadroBold border-b-[1px] lg:border-b-0  border-white py-[40px] lg:py-[40px]"
							onClick={() => {
								track("/kontakt(subpage)")
								return setShowMenu(false)
							}}
						>
							Kontakt
						</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default MobileMenu

import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	hasError?: boolean
	errorMsgPadding?: string
}

const ElemSelect: React.FC<InputProps> = ({ className, props, label, errMsg, hasError, children, errorMsgPadding }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block select-item " + (className || "")}>
			<select
				className={
					"text-left break-words font-semibold font-Roboto w-full rounded-[5px] px-4 py-4 lg:py-0  lg:text-base border-[1px]  leading-[22px] lg:leading-[47px] placeholder-black outline-0 mb-4 appearance-none bg-white " +
					(hasError ? "  js-formerror border-red" : " border-bordergray") +
					(props.name !== "salutation" ? " text-[13px] lg:text-base " : " text-base")
				}
				{...props}
			>
				{children}
			</select>
			{hasError && (
				<div ref={animationRef}>
					<div className={errorMsgPadding ? "formErrorMsg text-left " + errorMsgPadding : " formErrorMsg pb-2 text-left "}>{errMsg}</div>
				</div>
			)}
		</label>
	)
}

export default ElemSelect

import React, { createContext, useReducer } from "react"
import Reducer from "./reducer"

export const initialState = {
	salutation: "",
	firstname: "",
	surname: "",
	email: "",
	participationState: "",
	question1: "",
	question2: "",
	question3: "",
	question4: "",
	question5: "",
	age: "",
	code: "",
	optInMarketing: false,
	optInSurvey: false,
	receiptReference: null,
	receiptFileName: null,
	street: "",
	housenr: "",
	zip: "",
	city: "",
	testwinningprice: 0,
	shirtsize: "",
	soccertablelogo: "",
}

const Store = ({ children }) => {
	const [globalState, dispatch] = useReducer(Reducer, initialState)
	return <Context.Provider value={{ globalState, dispatch }}>{children}</Context.Provider>
}
export const Context = createContext<{
	globalState
	dispatch: React.Dispatch<any>
}>({
	globalState: initialState,
	dispatch: () => {},
})
export default Store

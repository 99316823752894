import { useEffect } from "react"
import Hero from "../components/home/Hero"
import Brands from "../components/Brands"
import { Link } from "react-router-dom"

function Loosing() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "customClick", link: label })
		} catch (e) {}
	}

	return (
		<>
			<Hero page="danke" />
			<div className="text-center relative z-0 -mt-[1px]  px-4 pt-6 lg:pt-12 pb-24 max-w-[900px] mx-auto">
				<>
					<h2 className="h2 mt-12 lg:leading-[52px] mb-12">DU HAST LEIDER NICHT GEWONNEN. VERSUCHE ES NOCH EINMAL!</h2>
					<p className=" mb-12 ">Lade einen weiteren Kassenbon hoch oder löse einen weiteren Code ein und gewinne mit etwas Glück.</p>
					<div className="text-center pt-5 py-10">
						<Link to="/teilnehmen" className="btn btn-invert mt-8" onClick={() => track("Nochmal versuchen")}>
							Nochmal versuchen
						</Link>
					</div>
				</>
			</div>

			<Brands />
		</>
	)
}
export default Loosing

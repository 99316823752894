import React, { useEffect, useContext } from "react"
import Hero from "../components/home/Hero"
import Form from "../components/Forms/Form"
import Brands from "../components/Brands"
import { Context } from "../store/store"

const Teilnehmen: React.FC = () => {
	const { globalState } = useContext(Context)
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<Hero page="teilnehmen" />
			<Form />
			<Brands code={globalState.participationState} />
		</>
	)
}
export default Teilnehmen

import { Link } from "react-router-dom"
function track(label: string) {
	try {
		// eslint-disable-next-line
		let dataLayer = window["dataLayer"] || []
		dataLayer.push({ event: "customClick", link: label })
	} catch (e) {}
}
const isPromoOver = parseInt(Math.floor(Date.now() / 1000).toString()) < 1716271200
const Intro = () => {
	return (
		<>
			<div className="bg-white max-w-[1400px] mx-auto px-4">
				<div className="text-center pt-2 mb-6">
					<h2 className="h2 max-w-[700px] mx-auto  mt-12 lg:mt-[73px] lg:leading-[60px]">
						{isPromoOver ? "Das Gewinnspiel startet am 21.05.2024" : "Mitmachen & Täglich gewinnen!"}
					</h2>
				</div>
				<div className="text-center max-w-[780px] mx-auto mb-[80px] p-4">
					<p className="text-black mb-[45px] lg:mb-[70px]">
						Eine Kiste Bier der teilnehmenden Marken kaufen oder Bier in einer teilnehmenden Location bestellen, Kassenbon hochladen oder Code eingeben und mit etwas Glück tolle
						Preise gewinnen!
					</p>
					{!isPromoOver && (
						<Link to="/teilnehmen" className="btn drop-shadow-md hover:drop-shadow-xl" onClick={() => track("jetzt teilnehmen")}>
							Jetzt Teilnehmen
						</Link>
					)}
				</div>
			</div>
		</>
	)
}

export default Intro

import { useEffect } from "react"
import Hero from "../components/home/Hero"
import Brands from "../components/Brands"

function Promoover() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="">
			<Hero page="danke" />
			<div className="text-center relative z-0 -mt-[1px]  px-4 pt-6 lg:pt-12 pb-24 max-w-[900px] mx-auto">
				<h2 className="h2 mt-12 lg:mt-24 lg:leading-[52px] mb-12">Unsere Aktion startet in Kürze</h2>
				<p className=" ">Ab dem 21.05.2024 kannst du hier teilnehmen und dir deine Gewinnchance sichern! </p>
			</div>
			<Brands />
		</div>
	)
}
export default Promoover

import { useContext, useState } from "react"
import { MINCODELETTERS } from "../../config"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import { checkValidCodes } from "../helper/codes.helper"

const Scratchcard = ({ formElementChanged, validation }) => {
	const { dispatch } = useContext(Context)
	const [code, setCode] = useState("")
	const [codeValid, setCodeValid] = useState("")

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "customClick", link: label })
		} catch (e) {}
	}

	async function checkCodes() {
		console.log("checkcodes", code, code.length, MINCODELETTERS)
		if (code.length === MINCODELETTERS) {
			let isCodeValid = await checkValidCodes(code)
			if (isCodeValid.data.valid) {
				dispatch({
					type: "SET_CODE",
					key: "code",
					value: code,
				})
				setCodeValid("valid")
			} else {
				setCodeValid("invalid")
			}
		} else {
			setCodeValid("invalid")
		}
	}

	return (
		<>
			<section className="pt-4 relative">
				<div className="container text-center">
					<h2 className="h2 mt-12 lg:mt-24 text-center text-yellow">Code eingeben</h2>
					<p className="text-black lg:max-w-[75%] mx-auto mt-4 lg:mt-10">Gib hier den Code von Deinem Los ein. Bitte achte darauf, dass der Code vollständig eingebeben wird.</p>
					<div className="p-1 sm:p-8">
						<div
							className={
								"relative px-4 lg:px-8 py-8 lg:py-16 lg:pb-8 grid grid-cols-1 lg:grid-cols-2 items-center bg-lightgray rounded-3xl mt-20 lg:mt-12 before:content-[''] before:absolute before:bg-[url(/src/bgimages/scratch-card.svg)] justify-items-center before:w-[60px] hover:bg-no-repeat before:h-[60px] before:left-[44%] md:before:left-[47%] lg:before:left-[47%] before:-top-[25px] " +
								(codeValid === "valid" ? " before:bg-[url(/src/bgimages/icon-uploaded-complete.svg)]" : "") +
								(codeValid === "invalid" ? " before:bg-[url(/src/bgimages/scratch-card-error.svg)]" : "")
							}
						>
							<div>
								<ElemInput
									label={"Code*"}
									props={{
										type: "text",
										name: "code",
										required: "required",
										minlength: 9,
										maxlength: 9,
										placeholder: "Code eingeben*",
										onChange: e => setCode(e.target.value.toUpperCase()),
										className: "rounded-[80px] p-4 w-full block uppercase",
										disabled: codeValid === "valid" ? true : false,
									}}
									hasError={validation["invalid_code"] ? true : false}
									className="col-span-2 md:col-span-1 w-[300px] lg:w-[350px] mt-6 lg:mt-0 text-center"
									errMsg={validation["invalid_code_errMsg"] || "Bitte gib einen Code ein"}
								/>
							</div>
							<a
								className={"btn mt-6 lg:mt-0 cursor-pointer " + (codeValid === "valid" ? "bg-black border-black cursor-not-allowed" : "")}
								onClick={() => {
									checkCodes()
									track("Code aktivieren")
								}}
							>
								{codeValid === "valid" ? "Code aktiviert" : "Code aktivieren"}
							</a>
							{codeValid === "invalid" && <p className="text-red text-[12px] lg:text-[16px] mt-6 col-span-1 lg:col-span-2">Fehler: Code ist ungültig.</p>}
							{codeValid === "valid" && <p className="text-yellow text-[12px]  lg:text-[16px] mt-6 col-span-1 lg:col-span-2">Der Code wurde erfolgreich aktiviert.</p>}
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Scratchcard

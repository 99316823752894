import { useEffect, useState, useContext } from "react"
import { Link, useSearchParams, useNavigate } from "react-router-dom"
import axios from "axios"
import { getEnvConfig } from "../env"
import { ERRORTYPES } from "../config"
import Hero from "../components/home/Hero"
import Brands from "../components/Brands"
import ElemInput from "../components/elements/Input"
import { Context } from "../store/store"
import { placeWinnerAdress } from "../components/Forms/WinnerSuccessFct"
import { useLocation } from "react-router-dom"
import { WINNERPRICES } from "../config"
import ElemSelect from "../components/elements/Select"

function WinnerAddress() {
	const env = getEnvConfig()
	const { globalState, dispatch } = useContext(Context)
	const [validation, setValidation] = useState({})
	const navigate = useNavigate()
	const [formError, setFormError] = useState<ERRORTYPES | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [searchParams] = useSearchParams()
	const [hashStateSet, setHashStateSet] = useState(false)
	const confirmationid = searchParams.get("hash")
	const [successState, setSuccessState] = useState(false)

	interface locationProps {
		state?: any
	}
	const location: locationProps = useLocation()

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "customClick", link: label })
		} catch (e) {}
	}
	const checkWinner = () => {
		if (location.state.price === 0 || location.state.price == null || location.state.price == undefined) {
			setHashStateSet(true)
		} else {
			setHashStateSet(false)
		}
	}

	function formElementChanged(e, key) {
		// special handling for checkboxes
		dispatch({
			type: "SET_ORDER_ENTRY",
			key: key,
			value: e.target.value
				.replace(/(<([^>]+)>)/gi, "")
				.replace("https://www", "")
				.replace("mailto:", ""),
		}) // strip HTML tags
		let isFieldInvalid = false

		const doc: any = document
		const elem = doc.querySelector("[name='" + key + "']:valid")
		if (elem === null) {
			isFieldInvalid = true
		}
		// check validation
		setValidation({ ...validation, ["invalid_" + key]: isFieldInvalid })
	}

	useEffect(() => {
		console.log("scroll to top")

		checkWinner()

		setTimeout(() => {
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			})
		}, 500)
	}, [])

	function submitOrder() {
		// create order part
		const orderSuccessCallback = extId => {
			if (extId) {
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "particpate", "form-submit": false, field_error: "send" })
				} catch (e) {}
				setSuccessState(true)
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth",
				})
			} else {
				setFormError(ERRORTYPES.SERVER)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
				} catch (e) {}
			}
		}
		const orderErrCallback = (tooManyAttemps: false, invalidCaptcha: false) => {
			setIsLoading(false)

			//TODO: Handling needs to be switched to error codes (BE part still open)
			if (tooManyAttemps) {
				setFormError(ERRORTYPES.MAXTRIES)
			} else if (invalidCaptcha) {
				setValidation({ ...validation, invalid_captcha: true })
				setFormError(null)
				scrollToError()
			} else {
				setFormError(ERRORTYPES.SERVER)
			}
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
			} catch (e) {}
		}

		if (allInputFieldsValid()) {
			if (globalState.receiptReference || globalState.code !== null) {
				setIsLoading(true)
				setFormError(null)
				placeWinnerAdress(globalState, location.state.hash, setFormValidation, orderSuccessCallback, orderErrCallback)
			} else {
				setFormError(ERRORTYPES.UPLOADSMISSINGRECEIPT)
			}
		} else {
			setFormError(ERRORTYPES.FORMVALIDATION)
			scrollToError()
		}
	}

	function isReceiptAndFotoGiven() {
		if (globalState.receiptReference && globalState.productImg) {
			return true
		}
		return false
	}

	function isBicRequired() {
		return globalState.iban && globalState.iban.length > 2 && globalState.iban.substring(0, 2).toUpperCase() !== "DE"
	}

	function isShirtWinner() {
		return location.state.price === 6
	}

	function isTableWinner() {
		return location.state.price === 5
	}

	function allInputFieldsValid() {
		// input names has to fit to variable classNames
		let isAllValid = true
		let invalidFields = {}

		// Special handing for bic. Bic is only for non DE IBANs required
		let fieldsToCheck: any = []
		if (isShirtWinner()) {
			fieldsToCheck = Object.keys(globalState).filter(
				e => e == "shirtsize" || e == "email" || e == "surname" || e == "firstname" || e == "street" || e == "zip" || e == "housenr" || e == "city"
			)
		} else if (isTableWinner()) {
			fieldsToCheck = Object.keys(globalState).filter(
				e => e == "soccertablelogo" || e == "email" || e == "surname" || e == "firstname" || e == "street" || e == "zip" || e == "housenr" || e == "city"
			)
		} else {
			fieldsToCheck = Object.keys(globalState).filter(e => e == "email" || e == "surname" || e == "firstname" || e == "street" || e == "zip" || e == "housenr" || e == "city")
		}

		for (let index = 0; index < fieldsToCheck.length; index++) {
			const key = fieldsToCheck[index]
			const doc: any = document
			const elem = doc.querySelector("[name='" + key + "']:valid")
			if (elem === null) {
				isAllValid = false
				invalidFields["invalid_" + key] = true
			}
		}
		if (Object.keys(invalidFields).length) {
			setValidation({ ...validation, ...invalidFields })
		}

		return isAllValid
	}

	function setFormValidation(data: {}) {
		setValidation({
			...validation,
			...data,
		})
		scrollToError()
	}

	function scrollToError() {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-formerror").getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY - 120,
					left: 0,
					behavior: "smooth",
				})
			} catch (error) {}
		}, 500)
	}

	return (
		<>
			<Hero page="winner" />
			<div className="text-center relative z-0 -mt-[1px] px-4 pt-6 lg:pt-12 pb-24 max-w-[900px] mx-auto">
				{!hashStateSet ? (
					<>
						{!successState ? (
							<>
								<h2 className="h2 mt-6 lg:leading-[52px] mb-12">
									Herzlichen Glückwunsch <br /> du hast gewonnen!
								</h2>
								<div className="bg-white drop-shadow-md rounded-lg mb-4 min-h-[290px] lg:min-h-[350px] max-w-[75%] lg:max-w-[40%] mx-auto ">
									<div className="grid grid-cols-1">
										<img
											src={process.env.PUBLIC_URL + "/images/radeberger/winningprices/" + WINNERPRICES[location.state.price - 1].imagename + ".png"}
											className="max-w-[100%] mx-auto"
										/>
										<p className="text-[18px] px-6 font-RobotoBold max-w-[270px] mb-4 mx-auto">{WINNERPRICES[location.state.price - 1].title}</p>
									</div>
								</div>
								{location.state.isReceipt && (
									<>
										<h2 className="h2 mb-12 mt-12 leading-[32px] lg:leading-[62px] text-yellow">Achtung: Gewinn unter Vorbehalt!</h2>
										<p className=" mb-12 font-bold">Bitte sende uns Deinen Kassenbon per Post an folgende Adresse:</p>
										<div className="max-w-[800px] mx-auto">
											<p className="font-bold">Postfach:</p>
											<p>Dein Bier - Dein Turnier</p>
											<p>Postfach 55 46</p>
											<p>33355 Rheda - Wiedenbrück</p>
											<p className="mt-12">
												Wir können dir den Gewinn erst zusenden, wenn wir Deinen Kassenbon erhalten und geprüft haben und dieser unseren Teilnahmebedingungen entspricht. Bitte
												sende uns zusammen mit Deinem Kassenbon auch Deine persönlichen Adressdaten mit Vor-, Nachname, Straße, Hausnummer, PLZ, Ort zu und trage sie hier
												zusätzlich ein.
												<br />
												<br />
												Bei weiteren Fragen wende dich bitte per E-Mail an{" "}
												<a href="mailto:info@deinbier-deinturnier.de" className="underline">
													info@deinbier-deinturnier.de
												</a>{" "}
												oder telefonisch unter:{" "}
												<a href="tel:+49696610236678" className="underline">
													069-6610236678
												</a>
												<br /> <br />
												Wir melden uns schnellstmöglich bei dir, wenn wir Deinen Kassenbon erhalten und geprüft haben. Weitere Infos findest du in unseren{" "}
												<Link to="/teilnahmebedingungen" className="underline" target="_blank" onClick={() => track("teilnahmebedingungen(subpage)")}>
													Teilnahmebedingungen
												</Link>
												.
											</p>
										</div>
									</>
								)}
								<p className="font-bold uppercase mb-6 mt-12">Hier Adressdaten angeben:</p>{" "}
								<p className=" mb-12 ">
									Damit wir dir Deinen Gewinn zusenden können, verrate uns bitte Deine persönlichen Adressdaten:​
									<br />
								</p>
								<form
									onSubmit={e => {
										e.preventDefault()
										submitOrder()
									}}
									noValidate={true}
									className=" max-w-[750px] mx-auto bg-white"
								>
									<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
										<ElemInput
											label={"Vorname*"}
											props={{
												type: "text",
												name: "firstname",
												value: globalState.firstname,
												required: "required",
												minLength: 1,
												placeholder: globalState.firstname ? globalState.firstname : "Vorname*",
												onChange: e => formElementChanged(e, "firstname"),
											}}
											hasError={validation["invalid_firstname"] ? true : false}
											className="col-span-2 md:col-span-1"
											errMsg={validation["invalid_firstname_errMsg"] || "Bitte gib Deinen Vornamen an."}
										/>
										<ElemInput
											label={"Nachname*"}
											props={{
												type: "text",
												name: "surname",
												value: globalState.surname,
												required: "required",
												minLength: 1,
												placeholder: globalState.surname ? globalState.surname : "Nachname*",
												onChange: e => formElementChanged(e, "surname"),
											}}
											hasError={validation["invalid_surname"] ? true : false}
											className="col-span-2 md:col-span-1"
											errMsg={validation["invalid_surname_errMsg"] || "Bitte gib Deinen Nachnamen an."}
										/>
										<ElemInput
											className="col-span-2 md:col-span-1"
											label={"E-Mail-Adresse*"}
											props={{
												type: "email",
												name: "email",
												value: globalState.email,
												required: "required",
												minLength: 1,
												placeholder: globalState.email ? globalState.email : "E-Mail-Adresse*",
												onChange: e => formElementChanged(e, "email"),
											}}
											hasError={validation["invalid_email"] ? true : false}
											errMsg={validation["invalid_email_errMsg"] || "Bitte gib Deine E-Mail-Adresse ein."}
										/>
										<ElemInput
											label={"Straße*"}
											props={{
												type: "text",
												name: "street",
												value: globalState.street,
												required: "required",
												minLength: 1,
												placeholder: globalState.street ? globalState.street : "Straße*",
												onChange: e => formElementChanged(e, "street"),
											}}
											hasError={validation["invalid_street"] ? true : false}
											className="col-span-2 md:col-span-1"
											errMsg={validation["invalid_street_errMsg"] || "Bitte gib Deine Straße an."}
										/>
										<ElemInput
											label={"Housenr*"}
											props={{
												type: "text",
												name: "housenr",
												value: globalState.housenr,
												required: "required",
												minLength: 1,
												placeholder: globalState.housenr ? globalState.housenr : "Hausnummer*",
												onChange: e => formElementChanged(e, "housenr"),
											}}
											hasError={validation["invalid_housenr"] ? true : false}
											className="col-span-2 md:col-span-1"
											errMsg={validation["invalid_housnr_rrMsg"] || "Bitte gib Deine Hausnummer an."}
										/>
										<ElemInput
											label={"PLZ*"}
											props={{
												type: "text",
												name: "zip",
												value: globalState.zip,
												required: "required",
												minLength: 5,
												maxLength: 5,
												placeholder: globalState.zip ? globalState.zip : "PLZ*",
												onChange: e => formElementChanged(e, "zip"),
											}}
											hasError={validation["invalid_zip"] ? true : false}
											className="col-span-2 md:col-span-1"
											errMsg={validation["invalid_zip_errMsg"] || "Bitte gib Deine PLZ an (5 Zeichen)."}
										/>

										<ElemInput
											label={"Stadt*"}
											props={{
												type: "text",
												name: "city",
												value: globalState.city,
												required: "required",
												minLength: 1,
												placeholder: globalState.city ? globalState.city : "Stadt*",
												onChange: e => formElementChanged(e, "city"),
											}}
											hasError={validation["invalid_city"] ? true : false}
											className="col-span-2 md:col-span-1"
											errMsg={validation["invalid_city_errMsg"] || "Bitte gib Deine Stadt an."}
										/>
										{location.state.price === 6 && (
											<ElemSelect
												label={"T-Shirt Größe*"}
												props={{
													type: "text",
													name: "shirtsize",
													value: globalState.shirtsize,
													required: "required",
													minLength: 1,
													placeholder: "T-Shirt Größe*",
													onChange: e => formElementChanged(e, "shirtsize"),
												}}
												hasError={validation["invalid_shirtsize"] ? true : false}
												className="col-span-2 md:col-span-1 text-left"
												errMsg={validation["invalid_salutation_errMsg"] || "Bitte trage Deine Größe ein."}
											>
												<>
													<option className="text-lightgray" value="">
														Größe
													</option>
													<option value="s">S</option>
													<option value="m">M</option>
													<option value="l">L</option>
													<option value="xl">XL</option>
													<option value="xxl">XXL</option>
												</>
											</ElemSelect>
										)}
										{location.state.price === 5 && (
											<ElemSelect
												label={"Welches Logo soll auf den Kickertisch bedruckt werden?*"}
												props={{
													type: "text",
													name: "soccertablelogo",
													value: globalState.soccertablelogo,
													required: "required",
													minLength: 1,
													placeholder: "Welches Logo soll auf den Kickertisch bedruckt werden?*",
													onChange: e => formElementChanged(e, "soccertablelogo"),
												}}
												hasError={validation["invalid_soccertablelogo"] ? true : false}
												className="col-span-2 md:col-span-1 text-left"
												errMsg={validation["invalid_soccertablelogo_errMsg"] || "Bitte wähle die Marke"}
											>
												<>
													<option className="text-lightgray" value="">
														Wähle Deine Lieblingsmarke*
													</option>
													<option value="a">Radeberger</option>
													<option value="b">Schöfferhofer</option>
													<option value="c">Jever</option>
													<option value="d">Oberdorfer Helles</option>
													<option value="e">Allgäuer Büble Bier</option>
													<option value="f">Binding</option>
													<option value="g">Brinkhoff's</option>
													<option value="h">Berliner Kindl</option>
													<option value="i">Stuttgarter Hofbräu</option>
													<option value="j">Ur-Krostitzer</option>
													<option value="k">Berliner Pilsener</option>
												</>
											</ElemSelect>
										)}
									</div>
									<div className="grid grid-cols-1  gap-4 items-center  mt-6">
										<p className="text-[14px] text-yellow self-center justify-start text-left">* Pflichfelder</p>
										<button type="submit" className="btn " disabled={isLoading ? true : undefined} onClick={() => track("Jetzt abschicken")}>
											Jetzt abschicken
										</button>
									</div>
								</form>
							</>
						) : location.state.isReceipt ? (
							<>
								<h2 className="h2 mt-12 lg:leading-[52px] mb-12">DEINE DATEN SIND BEI UNS EINGEGANGEN!</h2>
								<p className=" lg:mb-24 mb-12 max-w-[600px] mx-auto">
									Wir melden uns schnellstmöglich bei dir, wenn wir Deinen Kassenbon erhalten und geprüft haben. Weitere Infos findest du in unseren{" "}
									<Link to="/teilnahmebedingungen" target="_blank" className="underline">
										Teilnahmebedingungen
									</Link>
									.
									<br />
								</p>
								<Link to="/" className="btn lg:mt-12" onClick={() => track("Zurück zur Startseite")}>
									Zurück zur Startseite
								</Link>
							</>
						) : (
							<>
								<h2 className="h2 mt-12 lg:leading-[52px] mb-12">
									VIELEN DANK! <br />
									DEINE DATEN SIND BEI UNS EINGEGANGEN!
								</h2>
								<p className=" lg:mb-24 mb-12 ">
									Dein Gewinn wird nun an die von dir angegebene Adresse versandt.
									<br />
								</p>
								<Link to="/" className="btn lg:mt-12" onClick={() => track("Zurück zur Startseite")}>
									Zurück zur Startseite
								</Link>
							</>
						)}
					</>
				) : (
					<>
						<h2 className="h2 mt-12 lg:leading-[52px] mb-12">Sorry, hier scheint etwas nicht zu stimmen.</h2>
						<p className=" mb-12 ">Dieser Link ist nicht mehr gültig. Entweder gehörst du leider nicht zu den Gewinnern oder du hast bereits Deine Daten an uns übermittelt.</p>
						<div className="text-center pt-5 py-10">
							<Link to="/" className="btn btn-invert mt-8" onClick={() => track("Zurück zum Gewinnspiel")}>
								Zurück zum Gewinnspiel
							</Link>
						</div>
					</>
				)}
			</div>

			<Brands />
		</>
	)
}
export default WinnerAddress

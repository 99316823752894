import Header from "../Header"
import { Link } from "react-router-dom"
import React from "react"

interface HeroProps {
	page?: any
}
const Hero: React.FC<HeroProps> = ({ page }) => {
	return (
		<>
			<div className="bg-white relative max-w-[1400px] mx-auto">
				<Header />
				<div className="bg-white hero-image">
					{page === "home" && (
						<>
							<img src={process.env.PUBLIC_URL + "/images/radeberger/radeberger-header.jpg"} className=" w-full mx-auto hidden lg:block relative z-10 " alt="" />
							<img src={process.env.PUBLIC_URL + "/images/radeberger/radeberger-header-mobile.jpg"} className=" w-full mx-auto block lg:hidden relative z-10 " alt="" />
						</>
					)}
					{page === "teilnehmen" && (
						<>
							<img src={process.env.PUBLIC_URL + "/images/radeberger/radeberger-header-teilnehmen.png"} className=" w-full mx-auto hidden lg:block relative z-10 " alt="" />
							<img src={process.env.PUBLIC_URL + "/images/radeberger/radeberger-header-mobile.jpg"} className=" w-full mx-auto block lg:hidden relative z-10 " alt="" />
						</>
					)}
					{page === "participation" && (
						<img src={process.env.PUBLIC_URL + "/images/radeberger/radeberger-header-participation.png"} className=" w-full mx-auto relative z-10 " alt="" />
					)}
					{page === "winner" && (
						<>
							<img src={process.env.PUBLIC_URL + "/images/radeberger/radeberger-header-winner.png"} className="hidden lg:block w-full mx-auto relative z-10 " alt="" />
							<img src={process.env.PUBLIC_URL + "/images/radeberger/radeberger-header-winner-mobile.png"} className="block lg:hidden w-full mx-auto relative z-10 " alt="" />
						</>
					)}
					{page === "danke" && <img src={process.env.PUBLIC_URL + "/images/radeberger/radberger-header-danke.png"} className=" w-full mx-auto relative z-10 " alt="" />}
				</div>
			</div>
		</>
	)
}

export default Hero

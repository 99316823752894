declare const UC_UI: any
function Footer() {
	type TFooterLink = {
		text: string
		link: string
		track: string
		target?: string
	}

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	const footerLinks: TFooterLink[] = [
		{
			text: "Kontakt",
			link: "/kontakt",
			track: "/kontakt(subpage)",
		},
		{
			text: "Datenschutz",
			link: process.env.PUBLIC_URL + "/datenschutz",
			track: "/datenschutz(subpage)",
		},
		{
			text: "Impressum",
			link: process.env.PUBLIC_URL + "/impressum",
			track: "/impressum(subpage)",
		},
	]

	return (
		<footer className="bg-black py-4 lg:py-0 lg:h-[50px] grid items-center w-full mx-auto ">
			<div className="px-4 grid items-center grid-cols-1 lg:grid-cols-2">
				<div>
					<p className="text-center lg:text-left text-[16px] text-white mb-4 lg:mb-0">©Radeberger Gruppe KG 2024</p>
				</div>
				<div className="text-center lg:text-right">
					<nav>
						<ul>
							{footerLinks.map((footerLink, i) => (
								<li key={i} className="inline-block">
									<a
										href={footerLink.link}
										target={footerLink.target}
										className="text-[16px] font-Roboto pl-2 pr-2 lg:pr-4 lg:pl-4 text-white hover:underline"
										onClick={() => track(footerLink.track)}
									>
										{footerLink.text}
									</a>
								</li>
							))}

							<li className="inline-block">
								<span className="text-[16px] font-Roboto pl-2 pr-2 lg:pr-4 lg:pl-4 text-white hover:underline" onClick={() => UC_UI.showSecondLayer()}>
									Datenschutz Einstellungen
								</span>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</footer>
	)
}

export default Footer

import { useAutoAnimate } from "@formkit/auto-animate/react"

interface CheckboxProps {
	props?: any
	className?: string | null
	label?: any
	errMsg?: string
	hasError?: boolean
	errorMsgPadding?: string
}

const ElemCheckbox: React.FC<CheckboxProps> = ({ className, props, label, errMsg, hasError, errorMsgPadding }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block cursor-pointer " + (className || "")}>
			<input className={"w-1 h-1 hidden absolute peer "} {...props} />
			<img
				src={process.env.PUBLIC_URL + "/images/icon-checkbox.svg"}
				alt={"not selected"}
				loading="lazy"
				className="peer-checked:hidden block absolute w-[20px] h-[20px] lg:w-[20px] lg:h-[20px]  left-0 top-[5px]"
			/>
			<img
				src={process.env.PUBLIC_URL + "/images/icon-checkbox-checked.svg"}
				alt={"selected"}
				loading="lazy"
				className="peer-checked:block hidden absolute w-[20px] h-[20px] lg:w-[20px] lg:h-[20px] left-0 top-[5px]"
			/>
			<div className={"pl-10 lg:pl-12 pt-1 text-[12px] lg:text-[14px] leading-[20px] h-[20px] font-Quicksand text-black" + (hasError ? "  text-red js-formerror" : " text-black ")}>
				{label}
			</div>
			<div ref={animationRef}>{hasError && <div className={errorMsgPadding ? "formErrorMsg mt-4" + errorMsgPadding : " formErrorMsg mt-4"}>{errMsg}</div>}</div>
		</label>
	)
}

export default ElemCheckbox

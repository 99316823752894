import { Link } from "react-router-dom"
import { useState, useContext } from "react"
import { Context } from "../../store/store"

const ParticipationChoose = () => {
	const [accepted, setAccepted] = useState(false)
	const { globalState, dispatch } = useContext(Context)

	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "raffle", rafflestatus: label })
		} catch (e) {}
	}

	const chooseParticipationType = (type: string) => {
		dispatch({
			type: "SET_PARTICIPATION_STATE",
			key: "participationState",
			value: type,
		})
		setAccepted(true)
	}
	return (
		<>
			<div className={accepted ? "hidden" : "w-full h-full top-0 bottom-0 bg-black50 fixed z-[999] grid grid-cols-1 items-center overflow-x-hidden overflow-y-scroll"}>
				<div className="bg-white max-w-[95%] lg:max-w-[60%] mx-auto rounded-xl px-2 py-6 lg:px-24 lg:py-20">
					<div className="text-center pt-2 ">
						<h2 className="h2 max-w-[800px] mx-auto  mb-[15px] lg:mb-[30px] lg:leading-[42px] xl:leading-[60px]">Hast du einen Kassenbon oder einen Code vorliegen?</h2>
					</div>
					<div className="text-center mx-auto p-4">
						<div className="grid grid-cols-1 lg:grid-cols-1 2xl:grid-cols-2 lg:gap-4">
							<a
								className="btn drop-shadow-md w-[315px] px-12 hover:drop-shadow-xl cursor-pointer"
								onClick={() => {
									chooseParticipationType("receipt")
									track("receipt")
								}}
							>
								Kassenbon
							</a>
							<a
								className="btn drop-shadow-md w-[315px] mt-4 lg:mt-0 px-12 hover:drop-shadow-xl cursor-pointer"
								onClick={() => {
									chooseParticipationType("scratchcard")
									track("code")
								}}
							>
								Code einlösen
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ParticipationChoose

import { useContext } from "react"
import { Context } from "../../store/store"
import ElemSelect from "../elements/Select"
import ElemCheckbox from "../elements/Checkbox"
import ElemInputLabel from "../elements/ElemInputLabel"
import { QUESTIONTRANSLATION } from "../../config"

interface ProductsProps {
	formElementChanged: Function
	validation: Object
}
const ScratchCardProduct: React.FC<ProductsProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)
	return (
		<section className="relative max-w-[1000px] mx-auto mt-20  border-t-[1px] border-t-yellow lg:mt-24  lg:pt-24 after:font-QuadroBold after:block after:content-['2'] after:text-white after:pt-[10px]  after:pl-4 after:text-[24px] after:absolute after:rounded-full after:-top-[23px]  after:left-[44%] after:lg:left-[48%] after:w-[45px] after:h-[45px] after:bg-yellow after:mx-auto">
			<h3 className="text-center text-black font-RobotoBold pb-4 mb-24 lg:mb-14 mt-24 lg:mt-6 text-[20px] uppercase xl:text-[20px]">Bevor du absendest</h3>

			<div className="grid gap-5 grid-cols-1 items-start mb-2 sm:mb-12 max-w-[750px] mx-auto">
				<ElemInputLabel
					className="col-span-2 md:col-span-1"
					label={"In welchem Lokal hast Du Dein Bier bestellt? (z.B. Zur Linde)"}
					props={{
						type: "text",
						name: "question4",
						value: globalState.question4,
						minLength: 1,
						placeholder: "",
						maxLength: 30,
						onChange: e => formElementChanged(e, "question4"),
					}}
					hasError={validation["invalid_question4"] ? true : false}
					errMsg={validation["invalid_question4_errMsg"] || "Bitte gib Deine Daten an."}
				/>

				<ElemInputLabel
					className="col-span-2 md:col-span-1"
					label={"In welchem Ort/Stadt genießt Du gerade Dein Bier? (z.B. Wuppertal)"}
					props={{
						type: "text",
						name: "question5",
						value: globalState.question5,
						minLength: 1,
						placeholder: "",
						maxLength: 30,
						onChange: e => formElementChanged(e, "question5"),
					}}
					hasError={validation["invalid_question5"] ? true : false}
					errMsg={validation["invalid_question5_errMsg"] || "Bitte gib Deine Daten an."}
				/>
			</div>
		</section>
	)
}

export default ScratchCardProduct

import { useEffect } from "react"
import Hero from "../components/home/Hero"
import Brands from "../components/Brands"
import { Link } from "react-router-dom"

function WinnerConfirm() {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="">
			<Hero page="winner" />
			<div className="text-center relative z-0 -mt-[1px]  px-4 pt-6 lg:pt-12 pb-24 max-w-[900px] mx-auto">
				<h2 className="h2 mt-12 lg:mt-24 lg:leading-[52px] mb-12">VIELEN DANK!</h2>
				<h3 className="h2 mt-12 lg:mt-24 lg:leading-[52px] mb-12">DEINE DATEN SIND BEI UNS EINGEGANGEN!</h3>
				<p className=" ">Dein Gewinn wird nun an die von dir angegebene Adresse versandt.</p>
				<div className="text-center pt-5 py-10 mt-12">
					<a href="https://www.radeberger.de" target="_blank" className="btn btn-invert mt-12 mb-24">
						Zurück zur Startseite
					</a>
				</div>
			</div>
			<Brands />
		</div>
	)
}
export default WinnerConfirm

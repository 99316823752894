import React, { useEffect } from "react"
import { useNavigate, Link } from "react-router-dom"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

const Terms: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		// 	window.scrollTo({
		// 		top: 0,
		// 		left: 0,
		// 		behavior: "smooth",
		// 	})
	}, [])

	return (
		<div className="">
			<Hero page="danke" />
			<>
				<div className="container max-w-[800px] mx-auto px-4 text-darkgray ">
					<h2 className="h2 lg:pt-4 mt-4 text-[14px] lg:text-[26px] lg:mt-6 mb-6 break-all text-center">
						Teilnahmebedingungen <br />
						zur Aktion <br />
						„Dein Bier – Dein Turnier“ 2024
					</h2>

					<h3 className="h3 lg:text-[26px] text-darkgray py-4">1. Veranstalter der Aktion</h3>
					<p className="mb-4 ">Veranstalter der Aktion ist die Radeberger Gruppe KG, Darmstädter Landstraße 185, 60598 Frankfurt am Main</p>
					<h3 className="h3 lg:text-[26px] text-darkgray py-4">2. Zur Teilnahme berechtigte Personen</h3>
					<p className="mb-4">
						Teilnehmen darf jede natürliche Person ab 18 Jahren mit Wohnsitz in Deutschland, ausgenommen Mitarbeiter des Veranstalters, dessen verbundenen Unternehmen und
						Geschäfts-/Kooperationspartnern sowie deren jeweilige Angehörige. Mit der Teilnahme an dem Gewinnspiel erklärt sich der Teilnehmer mit diesen Teilnahmebedingungen
						einverstanden. Für die Teilnahme ist ein Internetzugang erforderlich. Die Teilnahme ist nur innerhalb Deutschlands möglich. Eine automatisierte Teilnahme ist nicht
						zulässig.
					</p>
					<h3 className="h3 lg:text-[26px] text-darkgray py-4">3. Teilnahme und Ablauf des Gewinnspiels</h3>
					<p className="mb-4">
						Die Teilnahme erfolgt ausschließlich online über die Aktionswebseite{" "}
						<a href="https://www.deinbier-deinturnier.de/" target="_blank" className="underline">
							www.deinbier-deinturnier.de
						</a>
						. Für die Teilnahme an dem Gewinnspiel „Dein Bier – Dein Turnier“ gibt es zwei Möglichkeiten: 1. Durch das Hochladen eines Kassenbons und 2. Durch die Eingabe eines
						Glückscodes aus den Losen der teilnehmenden Gastronomien.
					</p>
					<ul className="pl-8 list-decimal pb-4">
						<li>
							<p className="mb-4">
								Beim Upload des Kassenbons ist zudem die Eingabe der für die Teilnahme erforderlichen Daten (Anrede, Vor- und Nachname, E-Mail-Adresse, gekauftes Aktionsprodukt)
								erforderlich. Unvollständige oder fehlerhafte Angaben führen zum Ausschluss von der Aktion. Die Angabe des Geburtsjahrs ist freiwillig und dient der statistischen
								Auswertung der Aktion.{" "}
							</p>
							<p className="mb-4">
								Für die Teilnahme an der Aktion dürfen nur Kassenbons verwendet werden, aus denen sich ergibt, dass ein Kasten Bier (20 x 0,5 l MW, 11 x 0,5 l MW, 24 x 0,33 l MW,
								20 x 0,33 l MW, 4 x 6 x 0,33 l MW, 3 x 6 x 0,5 l MW) der aufgeführten Biermarken gekauft worden ist:
							</p>
							<ul className="pl-8 pb-4">
								<li>- Radeberger Pilsner und Radeberger Pilsner alkoholfrei </li>
								<li>- Jever alle Sorten</li>
								<li>- Schöfferhofer Weizen-Mix und Schöfferhofer Weizen alle Sorten </li>
								<li>- Oberdorfer Helles </li>
								<li>- Allgäuer Büble Bier alle Sorten </li>
								<li>- Binding alle Sorten </li>
								<li>- Brinkhoff’s alle Sorten </li>
								<li>- Berliner Kindl alle Sorten </li>
								<li>- Stuttgarter Hofbräu alle Sorten </li>
								<li>- Ur-Krostitzer alle Sorten </li>
								<li>- Berliner Pilsner alle Sorten </li>
							</ul>
							<p className="mb-4">
								Auf dem Kassenbon müssen die folgenden Daten erkennbar sein: Datum, Uhrzeit, Einkaufsstätte, Produkt. Das Bild des Kassenbons ist als JPG, PNG oder PDF hochzuladen
								und darf die maximale Dateigröße von 8 MB nicht überschreiten. Jeder Kassenbon kann nur einmal eingesetzt werden und ist im Gewinnfall an den Veranstalter zu
								übersenden.
							</p>
						</li>
						<li>
							<p>
								Ebenfalls teilnahmeberechtigt sind die Glückscodes der Lose der teilnehmenden Gastronomie. Jeder Teilnehmer, der in einer teilnehmenden Gastronomie ein Glas der
								oben genannten Biere bestellt, bekommt von dem Gastronom ein Los mit einem Glückscode übergeben.
							</p>
						</li>
					</ul>
					<p className="mb-4">
						Jeder Glückscode kann nur einmal eingegeben werden. Die im Gewinnfall anzugebenden Daten (Vorname, Nachname, E-Mail-Adresse, Anschrift, Volljährigkeit, ggf.
						Kleidergröße) sind vollständig und richtig anzugeben.
					</p>
					<p className="mb-4">Die Mehrfachteilnahme an der Aktion ist möglich. Es erfolgt jedoch nur eine Gewinnausschüttung je Teilnehmer.</p>
					<p className="mb-4">
						Im Anschluss an die Aktion wird eine Online-Umfrage durchgeführt, um zu ermitteln, ob und wie sich die Aktion noch verbessern lässt. Die Teilnahme an der Umfrage ist
						freiwillig und erfolgt durch Anklicken von „Ja, ich möchte an einer Umfrage zur Verbesserung der Aktion teilnehmen”. Über den an die angegebene E-Mail-Adresse sodann
						versendeten Link gelangen die Teilnehmer zur Online-Umfrage.
					</p>
					<p className="mb-4">
						Der Veranstalter prüft im Anschluss die Einhaltung der Teilnahmevoraussetzungen. Für die vollständige und korrekte Angabe der Personendaten ist der Teilnehmer
						verantwortlich.
					</p>
					<p className="mb-4">
						<strong>Aktionszeitraum:</strong> 21.05.2024 – 15.07.2024.
					</p>
					<p className="mb-4">
						<strong>Teilnahmeschluss:</strong> Teilnahmeschluss ist der 15.07.2024. Dabei gilt das Eingangsdatum des ausgefüllten Formulars. Spätere Teilnahmen werden nicht
						berücksichtigt, unabhängig davon, worauf die Verspätung zurückzuführen ist.
					</p>
					<h3 className="h3 lg:text-[26px] text-darkgray py-4">3.1 Gewinne</h3>
					<ul className="pl-8 pb-4 list-disc">
						<li>70 x Philips Smart TV 55 Zoll </li>
						<li>
							70 x Big Green Egg L Premium Starterpaket. Inhalt des Large-Starterpakets: Egg Large, Egg Nest+Handler, convEGGtor, 4,5 kg Holzkohle, Grillanzünder, Ascheschieber{" "}
						</li>
						<li>75 x Lautsprecher Sony SRS-XP 500 </li>
						<li>75 x PlayStation 5-Konsole – EA Sports FC 24 Bundle </li>
						<li>5 x Kickertisch Deiner Lieblingsbiermarke</li>
						<li>150 x Deutschland Fan-Trikots </li>
					</ul>
					<p className="mb-4">
						Eine Barauszahlung des Gewinnwertes bzw. ein Umtausch des Gewinns ist nicht möglich. Der Veranstalter behält sich das Recht vor, einen Preis gegen einen anderen
						vergleichbaren Preis auszutauschen, sofern dieser nicht mehr lieferbar ist.
					</p>

					<h3 className="h3 lg:text-[26px] text-darkgray py-4">3.2 Ermittlung und Information der Gewinner / Inanspruchnahme des Gewinns</h3>
					<p className="mb-4">
						Die Gewinnermittlung erfolgt per Los und resultiert aus dem Zufallsprinzip. Im Gewinnfall erscheint die Gewinnbenachrichtigung direkt nach dem Upload des Kassenbons
						oder nach Eingabe des Codes auf der Aktionsseite und der Gewinner erhält zudem eine E-Mailbenachrichtigung. Die Gewinner haben dann die erforderlichen Daten (Vorname,
						Nachname, E-Mail-Adresse, Anschrift, Volljährigkeit, ggf. Kleidergröße) vollständig und richtig anzugeben und werden dann zusätzlich per E-Mail von der direct services
						Gütersloh GmbH benachrichtigt. Im Gewinnfall muss der Kassenbon binnen 14 Tagen zur Gewinn-Verifizierung an folgende Adresse eingesendet werden: <br />
						<br />
						Dein Bier – Dein Turnier
						<br />
						Postfach 56 09
						<br />
						33356 Rheda-Wiedenbrück
					</p>

					<p className="mb-4">Der Teilnehmer hat keinen Gewinnanspruch, wenn</p>
					<ul className="pl-8 pb-4 list-disc">
						<li>der Original-Kassenbon nicht fristgerecht zugeht,</li>
						<li>oder der eingesandte Bon nicht der Bon ist, der auf der Aktionsseite hochgeladen wurde,</li>
						<li>oder der hochgeladene Bon ansonsten nicht den Teilnahmebedingungen entspricht.</li>
					</ul>
					<p className="mb-4">
						Geht der Kassenbon fristgerecht ein (maßgeblich hierbei ist der Poststempel), meldet sich die direct services Gütersloh GmbH binnen 2 Wochen per E-Mail bei dem
						Gewinner, um die Versandabwicklung des Gewinns zu besprechen.
					</p>
					<p className="mb-4">Die Kosten und das Risiko des Versands tragen die Gewinner selbst. Wir empfehlen einen versicherten Versand oder Versand per Einschreiben.</p>
					<p className="mb-4">
						Soweit nicht anders angegeben, werden alle Gewinne innerhalb von 4 Wochen per Post innerhalb Deutschlands versendet. Für eventuelle Verzögerungen bei der Zustellung
						übernimmt der Veranstalter keine Haftung.
					</p>
					<p className="mb-4">
						Bei fehlgeschlagener Gewinnzustellung (z.B. aufgrund einer falsch angegebenen Lieferadresse oder der Abwesenheit des Empfängers) wird die Sendung dem zweiten
						Zustellversuch an den Veranstalter zurückgesendet und der Gewinnanspruch verfällt automatisch.
					</p>
					<h3 className="h3 lg:text-[26px] text-darkgray py-4">4. Vorzeitige Beendigung, Ausschluss</h3>
					<ul className="pl-8 " style={{ listStyleType: "lower-latin" }}>
						<li>
							Der Veranstalter behält sich vor, die Aktion jederzeit ohne Vorankündigung und ohne Angabe von Gründen abzubrechen oder zu beenden. Dies gilt insbesondere, falls eine
							ordnungsgemäße Durchführung aus technischen oder rechtlichen Gründen nicht gewährleistet werden kann. Den Teilnehmern stehen in einem solchen Fall keine Ansprüche
							gegen den Veranstalter zu.
						</li>
						<li>
							Der Veranstalter behält sich vor, Teilnehmer von der Aktion auszuschließen, die gegen die Teilnahmebedingungen verstoßen und/oder Manipulationen vornehmen. Der
							Ausschluss kann in diesem Fall auch nach der Teilnahme erfolgen und hat die Verweigerung bzw. Rückforderung des Gewinns zur Folge.
						</li>
						<li>
							Der Veranstalter behält sich vor, die Teilnahmebedingungen zu ändern oder den zeitlichen Rahmen auszuweiten oder zu begrenzen oder die Gewinne zu variieren, sofern
							hierfür sachliche Gründe vorliegen und die Änderung angemessen ist. Der Veranstalter wird etwaige neue Bedingungen auf seiner Homepage{" "}
							<a href="https://www.deinbier-deinturnier.de/" target="_blank" className="underline">
								www.deinbier-deinturnier.de
							</a>{" "}
							bekanntgeben.
						</li>
					</ul>

					<h3 className="h3 lg:text-[26px] text-darkgray py-4">5. Haftung</h3>
					<p className="mb-4">
						Der Veranstalter haftet nicht für Schäden aus der Beeinträchtigung der Verfügbarkeit der Aktionsseite und auch nicht für deren Funktionsfähigkeit auf dem vom Teilnehmer
						eingesetzten Rechner, Smartphone etc. Für Datenverluste insbesondere im Wege der Datenübertragung und andere technische Defekte wird keine Haftung übernommen. Der
						Veranstalter übernimmt zudem keine Haftung für Irrtümer oder Druckfehler sowie für im Zusammenhang mit der Inanspruchnahme von Gewinnen möglicherweise entstehende
						Schäden. Diese Haftungsbeschränkungen gelten nicht bei Vorsatz oder grober Fahrlässigkeit, in Fällen zwingender gesetzlicher Haftung sowie bei schuldhaft verursachten
						Körperschäden.
					</p>
					<h3 className="h3 lg:text-[26px] text-darkgray py-4">6. Sonstiges</h3>
					<ul className="pl-8 " style={{ listStyleType: "lower-latin" }}>
						<li>
							<p>Sollten einzelne Bestimmungen dieser Teilnahmebedingungen ungültig sein, bleibt die Gültigkeit der übrigen Bestimmungen unberührt.</p>
						</li>
						<li>
							<p>Es gilt deutsches Recht. Der Rechtsweg ist ausgeschlossen.</p>
						</li>
						<li>
							<p>Mit der Teilnahme erklärt sich der Teilnehmer mit diesen Teilnahmebedingungen einverstanden.</p>
						</li>
					</ul>

					<h3 className="h3 lg:text-[26px] text-darkgray py-4">7. Datenschutz</h3>
					<p className="mb-4">
						Die personenbezogenen Daten der Teilnehmenden werden zur Durchführung der Aktion, Dokumentation ihrer ordnungsgemäßen Durchführung, für statistische Zwecke sowie zur
						Weiterentwicklung und Verbesserung vergleichbarer Aktionen verarbeitet. Sie werden dabei auch an unseren Dienstleister direct services Gütersloh GmbH,
						Reinhard-Mohn-Straße 300, 33333 Gütersloh übermittelt. Für die Auswertung der Umfrage bedienen wir uns unseres Dienstleisters quantilope GmbH, Charlottenstraße 26,
						20257 Hamburg.
					</p>
					<p className="mb-4">
						Personenbezogene Daten von Teilnehmern werden grds. für 6 Monate nach Teilnahmeschluss, die von Gewinnern zu Nachweiszwecken bis zum Ablauf der zehnjährigen
						gesetzlichen Aufbewahrungsfrist gespeichert. Rechtsgrundlage der Datenverarbeitung ist Art. 6 Abs. 1 a), soweit es um statistische Zwecke und die
						Weiterentwicklung/Verbesserung vergleichbarer Aktionen geht, im Übrigen Art. 6 Abs. 1 b) und c) DSGVO.
					</p>
					<p className="mb-4">
						Der Teilnehmer hat – unter den gesetzlichen Voraussetzungen – ein Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Datenübertragbarkeit und
						Widerspruch (Art. 15-21 DSGVO), ggf. auf Widerruf einer erteilten Einwilligung (Art. 7 DSGVO) sowie auf Beschwerde bei einer Aufsichtsbehörde (Art. 77 DSGVO). Der
						Datenschutzbeauftragte des Veranstalters ist erreichbar unter: radeberger-gruppe@corporate-data-protection.com. Weitere Informationen zum Datenschutz sind unter{" "}
						<a href="https://www.deinbier-deinturnier.de/" target="_blank" className="underline">
							www.deinbier-deinturnier.de
						</a>{" "}
						sowie unter{" "}
						<a href="https://www.radeberger-gruppe.de/" target="_blank" className="underline">
							www.radeberger-gruppe.de
						</a>{" "}
						abrufbar.
					</p>
				</div>
			</>
			<div className="text-center pt-5 py-10">
				<Link to="/" className="btn btn-invert mt-8">
					Zurück
				</Link>
			</div>
		</div>
	)
}
export default Terms

import { useState } from "react"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import { Link } from "react-router-dom"
import MobileMenu from "./MobileMenu"

const Header = ({ isPromoOver = false, addClass = "" }) => {
	const [showMenu, setShowMenu] = useState(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	function scrollToAnchor(anchor) {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-" + anchor).getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY,
					left: 0,
					behavior: "smooth",
				})

				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "header", link: anchor })
				} catch (e) {}
			} catch (error) {}
		}, 300)
	}

	return (
		<>
			<div ref={animationRef}>{showMenu && <MobileMenu setShowMenu={setShowMenu} isPromoOver={isPromoOver} scrollToAnchor={scrollToAnchor} />}</div>

			<header className="absolute top-0 w-full z-[200] px-8 pt-8 pb-8">
				<div className="block text-left pr-6">
					<img
						src={process.env.PUBLIC_URL + "/images/icon-menu.png"}
						alt="Menu"
						loading="lazy"
						width="26"
						className=" w-[40px] xl:w-[60px] float-left cursor-pointer"
						onClick={() => setShowMenu(true)}
					/>
				</div>
			</header>
		</>
	)
}

export default Header

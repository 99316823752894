import React from "react"
import Slider from "react-slick"
import { PRODUCTOVERVIEW } from "../../config"

const Preisuebersicht = () => {
	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		swipeToSlide: true,
		initialSlide: 3,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					dots: false,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					dots: false,
					slidesToScroll: 1,
				},
			},
		],
	}
	return (
		<>
			<div className="bg-bggray relative max-w-[1400px] mx-auto text-center py-12 lg:pt-24">
				<h3 className="h3 mb-12 p-4 ">Preise im Gesamtwert von bis zu 400.000 €​</h3>

				<Slider {...settings}>
					{PRODUCTOVERVIEW.map((item, index) => {
						return (
							<div key={index} className="bg-white drop-shadow-md rounded-lg mb-4 min-h-[290px] lg:min-h-[350px] max-w-[90%]">
								<div className="grid grid-cols-1 h-[300px] lg:h-[355px]">
									<img src={process.env.PUBLIC_URL + "/images/radeberger/winningprices/" + item.imagename + ".png"} alt={item.title} className="max-w-[70%] mx-auto" />
									<p className="justify-self-end text-[18px]  h-[80px] px-6 font-RobotoBold max-w-[320px] align-self-end mx-auto">{item.title}</p>
								</div>
							</div>
						)
					})}
				</Slider>
			</div>
		</>
	)
}

export default Preisuebersicht

import { useContext } from "react"
import { Context } from "../../store/store"
import ElemInput from "../elements/Input"
import ElemSelect from "../elements/Select"

interface PersonProps {
	formElementChanged: Function
	validation: Object
}
const Person: React.FC<PersonProps> = ({ formElementChanged, validation }) => {
	const { globalState } = useContext(Context)

	return (
		<section className="relative  max-w-[1000px] mx-auto js-mitmachen border-t-[1px] border-t-yellow mt-20 lg:cpt-12 lg:pt-24  after:font-QuadroBold after:block after:content-['1'] after:text-white after:pt-[10px]  after:pl-4 after:text-[24px] after:absolute after:rounded-full after:-top-[23px] after:left-[44%] after:lg:left-[48%] after:w-[45px] after:h-[45px] after:bg-yellow after:mx-auto">
			<h3 className="text-center text-black font-RobotoBold pb-4 mb-6 mt-24 lg:mt-6 text-[20px] uppercase xl:text-[20px]">Angaben zu Deiner Person</h3>
			<div className="grid lg:gap-5 grid-cols-1 md:grid-cols-[1fr_1fr] items-start mb-2 sm:mb-12 max-w-[750px] mx-auto">
				<div className="col-span-1">
					<ElemSelect
						label={"Anrede*"}
						props={{
							type: "text",
							name: "salutation",
							value: globalState.salutation,
							required: "required",
							minLength: 1,
							placeholder: "Anrede*",
							onChange: e => formElementChanged(e, "salutation"),
						}}
						hasError={validation["invalid_salutation"] ? true : false}
						className="col-span-2 md:col-span-1 text-left"
						errMsg={validation["invalid_salutation_errMsg"] || "Bitte trage Deine Anrede ein."}
					>
						<>
							<option className="text-lightgray" value="">
								Anrede*
							</option>
							<option value="1">Mann</option>
							<option value="2">Frau</option>
							<option value="3">Divers</option>
						</>
					</ElemSelect>
				</div>
				<div className="hidden md:block"></div>
				<ElemInput
					label={"Vorname*"}
					props={{
						type: "text",
						name: "firstname",
						value: globalState.firstname,
						required: "required",
						minLength: 1,
						placeholder: "Vorname*",
						onChange: e => formElementChanged(e, "firstname"),
					}}
					hasError={validation["invalid_firstname"] ? true : false}
					className="col-span-2 md:col-span-1"
					errMsg={validation["invalid_firstname_errMsg"] || "Bitte gib Deinen Vornamen an."}
				/>
				<ElemInput
					label={"Nachname*"}
					props={{
						type: "text",
						name: "surname",
						value: globalState.surname,
						required: "required",
						minLength: 1,
						placeholder: "Nachname*",
						onChange: e => formElementChanged(e, "surname"),
					}}
					hasError={validation["invalid_surname"] ? true : false}
					className="col-span-2 md:col-span-1"
					errMsg={validation["invalid_surname_errMsg"] || "Bitte gib Deinen Nachnamen an."}
				/>
				<ElemInput
					className="col-span-2 md:col-span-1"
					label={"E-Mail-Adresse*"}
					props={{
						type: "email",
						name: "email",
						value: globalState.email,
						required: "required",
						minLength: 1,
						placeholder: "E-Mail-Adresse*",
						onChange: e => formElementChanged(e, "email"),
					}}
					hasError={validation["invalid_email"] ? true : false}
					errMsg={validation["invalid_email_errMsg"] || "Bitte gib Deine E-Mail-Adresse ein."}
				/>
				<ElemInput
					className="col-span-2 md:col-span-1"
					label={"Geburtsjahr (min. 18 J.)"}
					props={{
						type: "number",
						name: "age",
						value: globalState.age,
						placeholder: "Geburtsjahr",
						min: "1900",
						max: "2006",
						onChange: e => formElementChanged(e, "age"),
					}}
					hasError={validation["invalid_age"] ? true : false}
					errMsg={validation["invalid_age_errMsg"] || "Bitte gib Dein Geburtsjahr an."}
				/>
			</div>
		</section>
	)
}

export default Person

import axios from "axios"
import { getEnvConfig } from "../../env"

const backendValidationMapping = {
	firstName: "invalid_firstname",
	lastName: "invalid_surname",
	email: "invalid_email",
	shop: "invalid_merchant",
	accountHolder: "invalid_bankName",
	iban: "invalid_iban",
	bic: "invalid_bic",
	over18OptIn: "invalid_age",
	product: "invalid_package",
	age: "age",
	question2: "invalid_question2",
	code: "invalid_code",
	receiptDataEntries: "invalid_receipt",
}

export function placeOrder(data, setValidation, successCallback, errCallback) {
	const env = getEnvConfig()
	const formdata = new FormData()

	// converting object to formdata
	for (var key in data) {
		formdata.append(key, data[key])
	}

	axios
		.post(env.apibase + "order.php?cv=" + Date.now(), formdata, {
			headers: {
				"content-type": "multipart/form-data",
				Accept: "application/json",
			},
		})
		.then(res => {
			if (res.status === 200) {
				if (res.data.externalIdentifier) {
					successCallback(res.data.externalIdentifier, res.data.raffledPrizeCode, res)
				} else {
					parseOrderErrors(res.data.errors, setValidation)

					errCallback()
				}
			} else {
				errCallback()
			}
		})
		.catch(error => {
			if (error.response && error.response.status >= 400 && error.response.status < 500) {
				// for 40X cases
				if (error.response.status === 402) {
					errCallback(false, false, false, true)
				} else {
					errCallback()
					parseOrderErrors(error.response.data.errors, setValidation)
				}
			}

			// Throttling of participations
			for (const key in error.response.data.errors.children) {
				if (error.response.data.errors.children[key].errors !== undefined) {
					console.log(error.response.data.errors.children[key].errors)
					try {
						if (error.response.data.errors.children[key].errors[0] === "Choose between a code or receipt upload, but not both") {
							errCallback(false, false, true)
						} else {
							errCallback()
						}
					} catch (e) {
						errCallback()
					}
				}
			}
		})
}

function parseOrderErrors(errors, setValidation) {
	if (errors) {
		for (const key in errors.children) {
			if (errors.children[key].errors && backendValidationMapping[key]) {
				setValidation({
					[backendValidationMapping[key] + "_errMsg"]: errors.children[key].errors.join(" "),
					[backendValidationMapping[key]]: true,
					showValidation: true,
				})
			}
		}
	}
}

import axios from "axios"

const domains = {
	"int.fe.radeberger-em-2024.campaign-loyalty.com": {
		apibase: "/inte/",
		recaptchakey: "",
	},
	"prod.fe.radeberger-em-2024.campaign-loyalty.com": {
		apibase: "/",
		recaptchakey: "",
	},
	localhost: {
		apibase: "/inte/",
		recaptchakey: "",
	},
	"localhost:3000": {
		apibase: "/inte/",
		recaptchakey: "",
	},
}
export const getEnvConfig = () => {
	return domains[window.location.host] || { apibase: "", recaptchakey: "" }
}
export async function getPromoLimit(callback) {
	const env = getEnvConfig()
	await axios
		.get(env.apibase + "checkLimit.php?cv=" + Date.now())
		.then(res => {
			callback(res.data.limitReached)
		})
		.catch(error => {
			callback(false)
		})
}

export async function checkOrderCount() {
	const env = getEnvConfig()
	return await axios
		.get(env.apibase + "checkOrder.php?cv=" + Date.now())
		.then(res => {
			let digits = Array.from(String(res.data.ordersLeft))
			return digits
		})
		.catch(error => {
			return error.response.status
		})
}

export const getCookie = (name: String) => {
	function escape(s) {
		return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1")
	}
	var match = document.cookie.match(RegExp("(?:^|;\\s*)" + escape(name) + "=([^;]*)"))
	return match ? match[1] : null
}

export const setCookie = (name: String, val: Boolean) => {
	const date = new Date()
	const value = val
	date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000)
	document.cookie = name + "=" + value + "; expires=" + date.toUTCString() + "; path=/"
}

export enum ERRORTYPES {
	FORMVALIDATION = "Bitte fülle alle Felder korrekt aus.",
	RECEIPT_UPLOAD_ERROR = "Leider stimmt etwas mit Deinem Kassenbon nicht. Wir können keinen Kauf eines teilnehmenden Produktes erkennen. ",
	FILESIZE = " Bitte überprüfe ob der Kassenbon alle notwendigen Voraussetzungen erfüllt. Bei weiteren Problemen melde dich gern unter info@violife-rabatt.de.",
	DOUBBLED = "Dieser Kassenbon wurde bereits verwendet.",
	MAXTRIES = "Du hast heute bereits zu häufig an unserem Gewinnspiel teilgenommen. Bitte probiere es morgen noch einmal.",
	SERVER = "Bei der Übertragung Deiner Daten ist ein Fehler aufgetreten. Bitte versuche es erneut. Sollten die Probleme weiterhin bestehen, kannst Du Dich an unser Service-Team wenden. Du erreichst uns per E-Mail unter info@radeberger.de. Bitte entschuldige die Unannehmlichkeiten.",
	UPLOADSMISSING = "Bitte lade einen Kassenbon und ein Produktfoto hoch. ",
	UPLOADSMISSINGRECEIPT = "Bitte lade Deinen Kassenbon hoch.",
	UPLOADSMISSINGPRODUCT = "Bitte lade Dein Produktfoto hoch.",
	UPLOADERROR = "Bitte Code aktivieren oder Kassenbon hochladen.",
	RAFFLEENDED = "Diese Aktion ist leider schon beendet.",
}

export const WINNINGTEXT = {
	1: "Du hast 1€ Rabatt gewonnen.",
	2: "Du hast 2€ Rabatt gewonnen.",
	3: "Du bekommst den vollständigen Kaufpreis erstattet!",
}

export const MAX_PRODUCT_PRICE = 3.0
export const MINCODELETTERS = 9

export const PRODUCTOVERVIEW = [
	{
		title: "Deutschland Fan-Trikot",
		imagename: "jersey",
	},
	{
		title: "Philips Smart TV 55 Zoll",
		imagename: "smarttv",
	},
	{
		title: "PlayStation® 5-Konsole - EA SPORTS FC™ 24 Bundle",
		imagename: "playstation",
	},
	{
		title: "Lautsprecher Sony SRS-XP 500",
		imagename: "speaker",
	},
	{
		title: "Kickertisch von Deiner Lieblingsbiermarke",
		imagename: "kicker",
	},
	{
		title: "Big Green Egg L Premium Grill inkl. Starterpack",
		imagename: "grill",
	},
]

export const PRICES = [
	{
		value: "a",
		title: "Ich interessiere mich für alle Gewinne",
	},
	{
		value: "b",
		title: "Philips Smart TV 55 Zoll",
	},
	{
		value: "c",
		title: "PlayStation® 5-Konsole - EA SPORTS FC™ 24 Bundle",
	},
	{
		value: "d",
		title: "Lautsprecher Sony SRS-XP 500",
	},
	{
		value: "e",
		title: "Kickertisch von Deiner Lieblingsbiermarke",
	},
	{
		value: "f",
		title: "Big Green Egg L Premium Grill inkl. Starterpack",
	},
	{
		value: "g",
		title: "Deutschland Fan-Trikot",
	},
]

export const BRANDSONE = [
	{
		title: "Radeberger",
		imagename: "Radeberger_800x800",
		link: "https://www.radeberger.de/?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-24-rp_na_radeberger_beer_all&utm_source_platform=802&utm_term=802",
	},
	{
		title: "Schöfferhofer",
		imagename: "Schoefferhofer_800x800",
		link: "https://www.schoefferhofer.de/?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-24-shf_na_schoefferhofer_beer_weizen-mix&utm_source_platform=802&utm_term=802",
	},
	{
		title: "Jever",
		imagename: "Jever_800x800",
		link: "https://jever.de?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-24-jev_na_jever_beer_all&utm_source_platform=802&utm_term=802",
	},
	{
		title: "Oberdorfer Helles",
		imagename: "Oberdorfer_800x800",
		link: "https://www.oberdorfer-bier.de/?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-2024-oberdorferhell_na_oberdorfer-helles_beer_oberdorfer-helles&utm_source_platform=802&utm_term=802",
	},
	{
		title: "Allgäuer Büblebier",
		imagename: "Allgaeuer_800x800",
		link: "https://allgaeuer-bueble.de/?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-2024-bueble_na_allgaeuer-bueble_beer_all&utm_source_platform=802&utm_term=802",
	},
]
export const BRANDSTWO = [
	{
		title: "Binding",
		imagename: "Binding_800x800",
		link: "https://binding.de/?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-2024-bindg_na_binding_beer_binding&utm_source_platform=802&utm_term=802",
	},
	{
		title: "Brinkhoffs",
		imagename: "Bringhoffs_800x800",
		link: "https://www.brinkhoffs.de/?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-2024-brinkh_na_brinkhoffs_beer_all&utm_source_platform=802&utm_term=802",
	},

	{
		title: "Berliner Kindl",
		imagename: "Berliner_Kindl_800x800",
		link: "https://www.berliner-kindl.de/?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-2024-bk_na_berliner-kindl_beer_all&utm_source_platform=802&utm_term=802",
	},
	{
		title: "Berliner",
		imagename: "Berliner_800x800",
		link: "https://www.berliner-pilsner.de/?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-2024-bp_na_berliner-pilsner_beer_all&utm_source_platform=802&utm_term=802",
	},
	{
		title: "Stuttgarter Hofbräu",
		imagename: "Stuttgarter_Hofbrau_800x800",
		link: "https://www.stuttgarter-hofbraeu.de/?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-2024-shb_na_stuttgarter-hofbraeu_beer_all&utm_source_platform=802&utm_term=802",
	},
	{
		title: "Ur-Kroitzer",
		imagename: "Ur-Kroftizer_800x800",
		link: "https://www.ur-krostitzer.de/?utm_source=microsite&utm_medium=organic&utm_campaign=de-rdb-rfe_em-promo-2024-uk_na_ur-krostitzer_beer_all&utm_source_platform=802&utm_term=802",
	},
]

export const WINNERPRICES = [
	{
		value: "0",
		title: "Philips Smart TV 55 Zoll",
		imagename: "smarttv",
	},
	{
		value: "1",
		title: "PlayStation® 5-Konsole - EA SPORTS FC™ 24 Bundle",
		imagename: "playstation",
	},
	{
		value: "2",
		title: "Lautsprecher Sony SRS-XP 500",
		imagename: "speaker",
	},
	{
		value: "3",
		title: "Big Green Egg L Premium Grill inkl. Starterpack ",
		imagename: "grill",
	},
	{
		value: "4",
		title: "Kickertisch von Deiner Lieblingsbiermarke",
		imagename: "kicker",
	},
	{
		value: "5",
		title: "Deutschland Fan-Trikot",
		imagename: "jersey",
	},
]

export enum QUESTIONTRANSLATION {
	a = "Radeberger",
	b = "Schöfferhofer",
	c = "Jever",
	d = "Oberdorfer Helles",
	e = "Allgäuer Büble Bier",
	f = "Binding",
	g = "Brinkhoff's",
	h = "Berliner Kindl",
	i = "Stuttgarter Hofbräu",
	j = "Ur-Krostitzer",
	k = "Berliner Pilsener",
}

import { BRANDSONE } from "../config"
import { BRANDSTWO } from "../config"
const Brands = ({ code = "" }) => {
	return (
		<>
			<div className="bg-bggray relative max-w-[1400px] mx-auto text-center py-12 pt-12 lg:pt-24 px-12" id="brands">
				<h3 className="h3 mb-12">{code === "scratchcard" ? "Diese und viele andere Marken nehmen teil" : "Teilnehmende Marken"}</h3>

				{/* TODO: WHY????? 1 2 3  */}
				<div data-BRANDSONE className=" grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-8 lg:px-12 pb-12 xl:max-w-[80%] mx-auto hidden xl:grid">
					{BRANDSONE.map((item, index) => {
						return (
							<div key={index} className=" rounded-xl bg-white lg:py-4 xl:max-w-[175px]">
								<a href={item.link} target="_blank">
									<img src={process.env.PUBLIC_URL + "/images/radeberger/brands/" + item.imagename + ".png"} alt={item.title} className="max-w-[120px] w-full mx-auto" />
								</a>
							</div>
						)
					})}
				</div>
				<div data-BRANDSTWO className="grid-cols-2 md:grid-cols-3 xl:grid-cols-6 gap-8 xl:px-12 hidden xl:grid">
					{BRANDSTWO.map((item, index) => {
						return (
							<div key={index} className=" rounded-xl bg-white lg:py-4">
								<a href={item.link} target="_blank">
									<img src={process.env.PUBLIC_URL + "/images/radeberger/brands/" + item.imagename + ".png"} alt={item.title} className="max-w-[120px] w-full mx-auto" />
								</a>
							</div>
						)
					})}
				</div>
				<div data-BRANDSONE className="grid-cols-2 lg:grid-cols-3 gap-8 xl:px-12 grid xl:hidden">
					{BRANDSONE.map((item, index) => {
						return (
							<div key={index} className=" rounded-xl bg-white lg:py-4">
								<a href={item.link} target="_blank">
									<img src={process.env.PUBLIC_URL + "/images/radeberger/brands/" + item.imagename + ".png"} alt={item.title} className="max-w-[120px] w-full mx-auto" />
								</a>
							</div>
						)
					})}
					{BRANDSTWO.map((item, index) => {
						return (
							<div key={index} className=" rounded-xl bg-white lg:py-4">
								<a href={item.link} target="_blank">
									<img src={process.env.PUBLIC_URL + "/images/radeberger/brands/" + item.imagename + ".png"} alt={item.title} className="max-w-[120px] w-full mx-auto" />
								</a>
							</div>
						)
					})}
				</div>
			</div>
		</>
	)
}

export default Brands

import React, { useEffect, useContext } from "react"
import Hero from "../components/home/Hero"
import Brands from "../components/Brands"
import { Context } from "../store/store"
import { Link } from "react-router-dom"

const Confirm: React.FC = () => {
	const { globalState, dispatch } = useContext(Context)
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div className="bg-green">
			<Hero page="danke" />
			<div className="text-center relative  ">
				<div className=" max-w-[1400px]  mx-auto">
					<h2 className="h2 px-4 mt-12  max-w-[80%] lg:max-w-[65%] mx-auto lg:leading-[62px]">Vielen Dank für Deine Teilnahme!</h2>
					<p className="text-black mt-6 lg:mt-12 text-sm sm:text-lg pt-4 px-4 lg:max-w-[65%] mx-auto">
						{globalState.participationState === "receipt" &&
							"In Kürze erhältst du eine E-Mail zur Teilnahmebestätigung. Du hast eine weitere Kiste Bier der teilnehmenden Marken gekauft? Löse einen weiteren Bon ein und erhöhe Deine Gewinnchance!"}
						{globalState.participationState === "scratchcard" &&
							"In Kürze erhältst du eine E-Mail zur Teilnahmebestätigung. Du hast eine weitere Kiste Bier der teilnehmenden Marken gekauft? Löse einen weiteren Code ein und erhöhe Deine Gewinnchance!"}
					</p>
					<div className="text-center pt-6 lg:pt-12 mb-12 pb-10 px-4">
						<Link
							to="/teilnehmen"
							className="inline-block btn mt-8"
							onClick={() => {
								try {
									// eslint-disable-next-line
									let dataLayer = window["dataLayer"] || []
									dataLayer.push({ event: "confirmation", link: "zurueck-zur-aktion" })
								} catch (e) {}
							}}
						>
							{globalState.participationState === "receipt" && "Weiteren Kassenbon einlösen"}
							{globalState.participationState === "scratchcard" && "Weiteren Code einlösen"}
						</Link>
					</div>
				</div>
			</div>
			<Brands />
		</div>
	)
}
export default Confirm

import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	ref?: any
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	onClick?: any
	showLeftCount?: boolean
	hasError?: boolean
	suffix?: string
	errorMsgPadding?: string
}

const ElemInput: React.FC<InputProps> = ({ ref, className, props, label, onClick, errMsg, showLeftCount, hasError, suffix, errorMsgPadding }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "")}>
			<input
				ref={ref}
				className={
					(props.type === "date" ? "" : " peer ") +
					(props.type === "number" ? "pl-[25px]" : " peer ") +
					" font-semibold w-full rounded-[5px] px-4 font-Roboto py-4 lg:py-0 text-base border-[1px]  leading-[22px] lg:leading-[47px] placeholder-black outline-0 mb-4 appearance-none bg-white focus-visible:border-red" +
					(hasError ? "  js-formerror border-[1px] border-red" : " border-bordergray") +
					(props.type === "date" ? " uppercase" : "")
				}
				onClick={onClick}
				{...props}
			/>
			<span
				className={
					(props.type === "date" ? " " : "  peer-focus: transition ") +
					(!!props.value ? "" : "") +
					" pointer-events-none absolute py-1 font-Roboto left-0 top-0 text-base pl-2 pr-4 mt-14 lg:mt-11 leading-[18px] lg:leading-[47px] placeholder-black" +
					(hasError ? "  text-red" : " text-white ")
				}
			></span>
			{suffix && <div className="absolute top-0 right-3 text-brown">{suffix}</div>}
			{showLeftCount && props.maxLength && (
				<div className="absolute top-3 right-3 text-brown">
					{props.value.length}/{props.maxLength}
				</div>
			)}
			<div ref={animationRef}>{hasError && <div className={errorMsgPadding ? "formErrorMsg mb-2 " + errorMsgPadding : " formErrorMsg formErrorMsg mb-2 "}>{errMsg}</div>}</div>
		</label>
	)
}

export default ElemInput

// https://play.tailwindcss.com/tKldzVn3U1

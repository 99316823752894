import React, { useEffect } from "react"
import Hero from "../components/home/Hero"
import Sogehts from "../components/home/Sogehts"
import Preisuebersicht from "../components/home/Preisuebersicht"
import Intro from "../components/home/Intro"
import Brands from "../components/Brands"

const Home: React.FC = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<Hero page="home" />
			<Intro />
			<Preisuebersicht />
			<Sogehts />
			<Brands />
		</>
	)
}
export default Home

import { Link } from "react-router-dom"
import { useState } from "react"
import { setCookie } from "../env"

const AgeCheckOverlay = () => {
	const [ageAccepted, setAgeAccepted] = useState(false)

	const allowEntrance = () => {
		setCookie("radebergerger_u18", true)
		setAgeAccepted(true)
	}
	return (
		<>
			<div className={ageAccepted ? "hidden" : "w-full h-full top-0 bottom-0 bg-black50 fixed z-[999] grid grid-cols-1 items-center overflow-x-hidden overflow-y-scroll"}>
				<div className="bg-white max-w-[95%] lg:max-w-[60%] mx-auto rounded-xl px-2 py-6 lg:px-24 lg:py-20">
					<div className="text-center pt-2 ">
						<h2 className="h2 max-w-[800px] mx-auto  mb-[15px] lg:mb-[30px] lg:leading-[60px]">
							Willkommen bei <br />
							Dein Bier - Dein Turnier
						</h2>
					</div>
					<div className="text-center mx-auto p-4">
						<p className="text-black mb-[46px] max-w-[%] lg:max-w-[75%] mx-auto">
							Um am Gewinnspiel „Dein Bier - Dein Turnier“ teilzunehmen, musst du mindestens 18 Jahre oder älter sein. Kannst du uns das bestätigen?
						</p>
						<div className="grid grid-cols-1 2xl:grid-cols-2 gap-4">
							<a className="btn drop-shadow-md hover:drop-shadow-xl cursor-pointer" onClick={() => allowEntrance()}>
								Ja, ich bin über 18 Jahre
							</a>
							<a href="https://www.bierbewusstgeniessen.de/" target="_blank" className="btn-light drop-shadow-md hover:drop-shadow-xl">
								Nein, leider noch nicht
							</a>
						</div>
						<p className="text-yellow small mt-12">
							Die Internetseite von "Dein Bier - Dein Turnier" enthält Werbeinformationen zu alkoholischen Getränken. Als nationaler Premium-Bierhersteller tragen wir Verantwortung
							und setzen uns im Rahmen der gesetzlichen Bestimmungen für den verantwortungsbewussten Umgang mit alkoholischen Getränken ein. Daher bitten wir dich anzugeben, ob du
							mindestens 18 Jahre alt bist.
						</p>
					</div>
				</div>
			</div>
		</>
	)
}

export default AgeCheckOverlay

import React, { useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import AccordionItem from "../components/elements/AccordionItem"
import Hero from "../components/home/Hero"
import Header from "../components/Header"

const FAQ: React.FC = () => {
	const navigate = useNavigate()

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div>
			<Hero page="danke" />
			<>
				<div className="container max-w-[800px] mx-auto px-4 ">
					<h2 className="h2 lg:pt-4 mt-4 sm:text-[18px] lg:text-[34px] lg:mt-6 mb-6 lg:mb-12 break-all text-center">
						<p className="mb-6 lg:mb-12 text-[32px] lg:text-[52px]">FAQ</p>
						<p className="mb-3 text-[14px] lg:text-[34px]">„Dein Bier – Dein Turnier“</p>
					</h2>
					<AccordionItem headline="Wie und wann kann ich an dem Gewinnspiel teilnehmen?" num="1.">
						<p className="text-darkgray">
							Die Teilnahme erfolgt ausschließlich online über die Aktionswebsite{" "}
							<a href="https://www.deinbier-deinturnier.de" target="_blank" className="underline">
								www.deinbier-deinturnier.de
							</a>{" "}
							im Aktionszeitraum vom 21.05.2024 bis zum 15.07.2024.
						</p>
						<p className="mt-4 text-darkgray">
							1. Eine Kiste Bier der teilnehmenden Marken kaufen und Kassenbon auf der Aktionswebsite hochladen. Teilnahmedaten (Anrede, Vor- und Nachname, E-Mail Adresse und
							gekaufte Biermarke) eintragen und auf „Jetzt teilnehmen“ klicken. Jeder Kassenbon kann nur einmalig hochgeladen werden.
						</p>
						<p className="mt-4 text-darkgray">
							2. Bier in einer teilnehmenden Gastronomie bestellen, Los erhalten, Glückscode vom Los auf der Aktionswebsite eingeben und auf „Jetzt teilnehmen“ klicken. Jeder Code
							kann nur einmalig eingegeben werden.
						</p>
					</AccordionItem>
					<AccordionItem headline="Was kann ich gewinnen?" num="2.">
						<p className="text-darkgray">Mit etwas Glück kannst du einen der folgenden Preise gewinnen:</p>
						<ul className="mt-4 text-[16px] lg:text-[17px] list-disc list-inside text-darkgray">
							<li className="text-darkgray">70 x Philips Smart TV 55 Zoll </li>
							<li>
								70 x Big Green Egg L Premium Starterpaket. Inhalt des Large-Starterpakets: Egg Large, Egg Nest+Handler, convEGGtor, 4,5 kg Holzkohle, Grillanzünder, Ascheschieber{" "}
							</li>
							<li>75x Lautsprecher Sony SRS-XP 500 </li>
							<li>75x PlayStation 5-Konsole – EA Sports FC 24 Bundle </li>
							<li>5x Kickertisch deiner Lieblingsbiermarke</li>
							<li>150x Deutschland Fan-Trikots </li>
						</ul>
						<p className="mt-4 text-darkgray">
							Weitere Informationen findest du in den{" "}
							<Link to="/teilnahmebedingungen" target="_blank" className="underline">
								Teilnahmebedingungen
							</Link>
							.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wer darf an dem Gewinnspiel teilnehmen?" num="3.">
						<p className="text-darkgray">
							Teilnehmen darf jede natürliche Person ab 18 Jahren mit Wohnsitz in Deutschland, ausgenommen Mitarbeiter des Veranstalters, dessen verbundenen Unternehmen und
							Geschäfts-/Kooperationspartnern sowie deren jeweilige Angehörige. Die Teilnahme ist nur innerhalb Deutschlands möglich.
						</p>
					</AccordionItem>
					<AccordionItem headline="In welchen Zeitraum kann ich an dem Gewinnspiel teilnehmen?" num="4.">
						<p className="text-darkgray">Eine Teilnahme an dem Gewinnspiel ist im Aktionszeitraum vom 21.05.2024 bis 15.07.2024 möglich.</p>
					</AccordionItem>
					<AccordionItem headline="Welche Biermarke muss ich kaufen, um an dem Gewinnspiel per Kassenbon Upload teilzunehmen?" num="5.">
						<p className="text-darkgray">
							Für die Teilnahme an dem Gewinnspiel dürfen nur Kassenbons verwendet werden, aus denen sich ergibt, dass ein Kasten Bier (20 x 0,5 l MW, 11 x 0,5 l MW, 24 x 0,33 l
							MW, 20 x 0,33 l MW, 4 x 6 x 0,33 l MW, 3 x 6 x 0,5 l MW) der aufgeführten Biermarken gekauft worden ist:
						</p>
						<ul className="mt-4 text-[16px] lg:text-[17px] text-darkgray list-disc list-inside">
							<li>Radeberger Pilsner und Radeberger Pilsner alkoholfrei </li>
							<li>Jever alle Sorten</li>
							<li>Schöfferhofer Weizen-Mix und Schöfferhofer Weizen alle Sorten </li>
							<li>Oberdorfer Helles</li>
							<li>Allgäuer Büble Bier alle Sorten</li>
							<li>Binding alle Sorten</li>
							<li>Brinkhoff’s alle Sorten </li>
							<li>Stuttgarter Hofbräu alle Sorten</li>
							<li>Ur-Krostitzer alle Sorten</li>
							<li>Berliner Kindl alle Sorten</li>
							<li>Berliner Pilsner alle Sorten</li>
						</ul>
						<p className="mt-4 text-darkgray">
							Die Marke des bestellten Biers spielt in der Gastronomie keine Rolle. Im Falle eines Gewinns durch einen Glückscode muss dieser nicht eingereicht werden. Jeder Code
							kann nur einmal verwendet werden. Jeder Kassenbon kann nur einmal verwendet werden und muss im Gewinnfall an den Veranstalter übersendet werden.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wie lade ich den Kassenbon richtig hoch?" num="6.">
						<p className="text-darkgray">
							Es können beim Upload nur Fotos im Format JPG, PNG und PDF und einer maximalen Größe von 8 MB pro Foto hochgeladen werden. Auf dem Kassenbon müssen die folgenden
							Daten erkennbar sein: Kaufdatum, Kaufpreis des Aktionsproduktes, Bonsumme, Einkaufsstätte sowie das gekaufte Aktionsprodukt.{" "}
						</p>
					</AccordionItem>
					<AccordionItem headline="Mein Code funktioniert nicht, was kann ich tun?" num="7.">
						<p className="text-darkgray">
							Wenn du sicher bist, dass du den Code nicht aus Versehen schon einmal benutzt hast, und es sich auch nicht um einen Tippfehler handelt, schicke uns bitte ein Foto
							deines Codes per E-Mail an{" "}
							<a href="mailto:info@deinbier-deinturnier.de" className="underline" target="_blank">
								info@deinbier-deinturnier.de
							</a>{" "}
							zur Prüfung zu.
						</p>
					</AccordionItem>
					<AccordionItem headline="Kann ich mehrfach an dem Gewinnspiel teilnehmen?" num="8.">
						<p className="text-darkgray">
							Ja, es ist möglich, mehrmals teilzunehmen, indem unterschiedliche Kassenbons oder Codes verwendet werden. Da jeder Kassenbon nur einmal hochgeladen und jeder Code nur
							einmal eingegeben werden kann, benötigst du für eine neue Teilnahme immer einen neuen Kassenbon oder Code.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wie erfahre ich, ob ich gewonnen habe?" num="9.">
						<p className="text-darkgray">
							Die Gewinner werden per Losverfahren ermittelt. Im Gewinnfall erscheint die Gewinnbenachrichtigung direkt nach dem Upload des Kassenbons oder nach Eingabe des Codes
							auf der Aktionsseite und der Gewinner erhält zudem eine E-Mailbenachrichtigung. Im Falle eines Gewinns durch einen Glückscode muss dieser nicht eingereicht werden. Im
							Gewinnfall per Kassenbon Upload muss dieser binnen 14 Tagen zur Gewinn-Verifizierung an folgende Adresse eingesendet werden:
						</p>
						<p className="text-darkgray mt-4">
							Dein Bier – Dein Turnier <br />
							Postfach 56 09
							<br />
							33356 Rheda-Wiedenbrück
						</p>
						<p className="text-darkgray mt-4">
							Die Kosten und das Risiko des Versands tragen die Gewinner selbst. Wir empfehlen einen versicherten Versand oder Versand per Einschreiben. Sind die bei der Teilnahme
							angegebenen Daten falsch und kann deswegen kein Kontakt erfolgen, verfällt dein Gewinnanspruch.
						</p>
					</AccordionItem>
					<AccordionItem headline="Wie lange dauert es, bis ich meinen Gewinn erhalte?" num="10.">
						<p className="text-darkgray">
							Geht der Kassenbon fristgerecht ein (maßgeblich hierbei ist der Poststempel), werden wir uns innerhalb von 2 Wochen per E-Mail bei dir melden, um die
							Versandabwicklung des Gewinns zu besprechen.
						</p>
						<p className="text-darkgray">
							Wenn du per Glückscode gewonnen hast, werden wir uns innerhalb von einer Woche bei dir melden, um die Versandabwicklung des Gewinns zu sprechen.
						</p>
						<p className="text-darkgray">Soweit nicht anders angegeben, werden alle Gewinne innerhalb von 4 Wochen per Post innerhalb Deutschlands versendet.</p>
					</AccordionItem>
					<AccordionItem headline="Fallen Kosten bei der Versendung der Gewinne an?" num="11.">
						<p className="text-darkgray">Nein, es entstehen keine Versandkosten für die Gewinner. Die Gewinne werden innerhalb Deutschlands versandkostenfrei zugestellt.</p>
					</AccordionItem>
					<AccordionItem headline="Ist ein Versand des Gewinns ins Ausland möglich?" num="12.">
						<p className="text-darkgray">Nein, der Gewinnversand ist nur innerhalb Deutschlands möglich.</p>
					</AccordionItem>
					<AccordionItem headline="Was passiert mit meinen Daten?" num="13.">
						<p className="text-darkgray">
							Die personenbezogenen Daten der Teilnehmenden werden zur Durchführung der Aktion, Dokumentation ihrer ordnungsgemäßen Durchführung, für statistische Zwecke sowie zur
							Weiterentwicklung und Verbesserung vergleichbarer Aktionen verarbeitet. Sie werden dabei auch an unseren Dienstleister direct services Gütersloh GmbH,
							Reinhard-Mohn-Straße 300, 33333 Gütersloh übermittelt. Für die Auswertung der Umfrage bedienen wir uns unseres Dienstleisters quantilope GmbH, Charlottenstraße 26,
							20257 Hamburg. Weitere Informationen zum Datenschutz findest du{" "}
							<Link to="/datenschutz" target="_blank" className="underline">
								hier
							</Link>
							.
						</p>
					</AccordionItem>
					<AccordionItem headline="Deine Frage wurde noch nicht beantwortet?" num="14.">
						<p className="text-darkgray">
							Dann sende eine E-Mail an{" "}
							<a href="mailto:info@deinbier-deinturnier.de" className="underline" target="_blank">
								info@deinbier-deinturnier.de
							</a>{" "}
							oder wende dich telefonisch an unseren Kundenservice unter 069-6610236678. Unseren Kundenservice erreichst du montags bis freitags von 8:30 bis 17:30 Uhr.{" "}
						</p>
					</AccordionItem>
				</div>
			</>
			<div className="text-center pt-5 py-10">
				<Link to="/" className="btn btn-invert mt-8">
					Zurück
				</Link>
			</div>
		</div>
	)
}
export default FAQ

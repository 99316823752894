interface PopupProps {
	close: any
}
const Popup: React.FC<PopupProps> = ({ close, children }) => {
	return (
		<section className="transition duration-150 ease-in-out fixed z-[400] top-0 left-0 w-full h-full bg-black/30 z-20">
			<img
				src={process.env.PUBLIC_URL + "/images/icon-close.svg"}
				alt="Menu"
				loading="lazy"
				width="83"
				height="73"
				className=" w-[83px] cursor-pointer absolute top-12 left-[50%] drop-shadow-md -translate-x-[50%] bg-white p-4 rounded-[5px] right-4 z-10"
				onClick={close}
			/>
			<div className="w-96 absolute left-1/2 top-1/2 bg-white -translate-x-1/2 -translate-y-1/2 p-8 text-center">{children}</div>
		</section>
	)
}

export default Popup

import { Link } from "react-router-dom"
import { useState } from "react"
const Sogehts = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "customClick", link: label })
		} catch (e) {}
	}
	const isPromoOver = parseInt(Math.floor(Date.now() / 1000).toString()) < 1716271200
	return (
		<>
			<div className="bg-white max-w-[1400px] mx-auto  mb-24 text-center js-sogehts">
				<div className="relative max-w-[1400px] mx-auto text-center py-12 pt-12 lg:pt-24">
					<h3 className="h3 mb-6 lg:mb-12">So einfach geht's</h3>
				</div>
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-4 items-center justify-items-center text-center max-w-[80%] mx-auto">
					<div className="relative px-8 py-10 lg:px-14 lg:py-20 bg-lightgray rounded-xl lg:min-h-[428px] font-RobotoBold grid justify-items-center sm:w-full  after:block after:content-['1'] after:text-white after:pt-3 after:text-[24px] after:absolute after:rounded-full after:-top-[20px] after:w-[45px] after:h-[45px] after:bg-yellow">
						<img src={process.env.PUBLIC_URL + "/images/radeberger/sogehts/kasten.png"} className="mb-8"></img>
						<p>
							Eine Kiste Bier der{" "}
							<a href="#brands" className="underline" onClick={() => track("Teilnehmende Marken Startseite")}>
								teilnehmenden Marken
							</a>{" "}
							kaufen oder ein Bier in einer der teilnehmenden Locations bestellen.
						</p>
					</div>
					<div className="relative px-8 py-10  md:mt-12 lg:mt-0  lg:px-14 lg:py-20 bg-lightgray rounded-xl font-RobotoBold grid justify-items-center  sm:w-full  lg:min-h-[428px] after:block after:content-['2'] after:text-white after:pt-3 after:text-[24px] after:absolute after:rounded-full after:-top-[20px] after:w-[45px] after:h-[45px] after:bg-yellow">
						<img src={process.env.PUBLIC_URL + "/images/radeberger/sogehts/bon.png"} className="mb-8"></img>
						<p>Kassenbon hochladen oder Code auf der Aktionswebsite eingeben.</p>
					</div>
					<div className=" relative px-8 py-10 md:mt-12 lg:mt-0 lg:px-14 lg:py-20 bg-lightgray w-full rounded-xl font-RobotoBold grid justify-items-center  sm:w-full  lg:min-h-[428px]  after:block after:content-['3'] after:text-white after:pt-3 after:text-[24px] after:absolute after:rounded-full after:-top-[20px] after:w-[45px] after:h-[45px] after:bg-yellow">
						<img src={process.env.PUBLIC_URL + "/images/radeberger/sogehts/geschenk.png"} className="mb-8"></img>
						<p>Mit etwas Glück gewinnen!</p>
					</div>
				</div>
				<p className=" text-yellow text-center max-w-[75%] mx-auto mt-12 mb-12 ">
					Ab dem 21.05.2024 kannst du hier teilnehmen und dir deine Gewinnchance sichern! <br />
					Weitere Informationen findest du in den{" "}
					<Link to="/teilnahmebedingungen" className="underline" target="_blank" onClick={() => track("/teilnahmebedingungen (subpage)")}>
						Teilnahmebedingungen.
					</Link>
				</p>

				{!isPromoOver && (
					<Link to="teilnehmen" className="btn mx-auto drop-shadow-md hover:drop-shadow-xl" onClick={() => track("jetzt teilnehmen")}>
						Jetzt teilnehmen
					</Link>
				)}
			</div>
		</>
	)
}

export default Sogehts

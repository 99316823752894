import { useAutoAnimate } from "@formkit/auto-animate/react"
import { useContext, useState } from "react"
import { Context } from "../../store/store"
import Popup from "../elements/Popup"
import ReceiptUpload from "./ReceiptUpload"

const Receipt = ({ formElementChanged, validation }) => {
	const { globalState, dispatch } = useContext(Context)
	const [showSample, setShowSample] = useState<boolean>(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<>
			<section className="pt-4 relative">
				<div className="container text-center">
					<h2 className="h2 mt-2 lg:mt-12 text-center text-yellow">Kassenbon hochladen</h2>
					<p className="text-black lg:max-w-[75%] mx-auto mt-4 lg:mt-10">
						Lade jetzt Deinen Kassenbon hoch. Bitte achte darauf, dass er vollständig ist und Datum, Einkaufsstätte, Aktionsprodukt sowie Preis gut lesbar sind.
					</p>
					<div className="p-1 sm:p-8">
						<div
							className={
								"relative px-2 lg:px-8 py-8 lg:py-16 grid grid-cols-1 items-center bg-lightgray rounded-3xl mt-20 lg:mt-12 before:content-['']  justify-items-center before:w-[60px] before:h-[60px] hover:bg-no-repeat before:left-[50%] before:-translate-x-[50%] before:-top-[25px]" +
								(globalState.receiptReference !== null
									? " before:absolute before:bg-[url(/src/bgimages/icon-uploaded-complete.svg)]"
									: " before:absolute before:bg-[url(/src/bgimages/upload-icon.svg)]")
							}
						>
							<div>
								<ReceiptUpload dispatch={dispatch} />
							</div>
						</div>
						<p className="text-yellow text-[14px] mt-4 max-w-[90%] mx-auto lg:max-w-full">
							Möglich sind JPG-, PNG- oder PDF-Dateien bis max. 8 MB. Hier ein{" "}
							<a className="underline cursor-pointer" onClick={() => setShowSample(true)}>
								Beispiel
							</a>
							.
						</p>
					</div>
				</div>
			</section>

			<div ref={animationRef}>
				{showSample && (
					<Popup close={() => setShowSample(false)}>
						<h5 className="h5 pb-6">Beispiel-Kassenbon</h5>
						<img src={process.env.PUBLIC_URL + "/images/kassenbon.jpg"} width="724" height="1462" alt="" className="mx-auto w-[270px] lg:w-[770px] h-auto " />
					</Popup>
				)}
			</div>
		</>
	)
}

export default Receipt

import { BrowserRouter, Route, Routes, useSearchParams } from "react-router-dom"
import Footer from "./components/Footer"
import Contact from "./nav/Contact"
import FAQ from "./nav/FAQ"
import Home from "./nav/Home"
import Imprint from "./nav/Imprint"
import Privacy from "./nav/Privacy"
import Terms from "./nav/Terms"
import Error from "./nav/Error"
import Store from "./store/store"
import Confirm from "./nav/Confirm"
import Promoover from "./nav/PromoOver"
import PreScreen from "./nav/PreScreen"
import AgeCheckOverlay from "./components/ageCheckOverlay"
import { getCookie } from "./env"
import Teilnehmen from "./nav/Teilnehmen"
import WinnerAdressForm from "./nav/WinnerConfirm"
import WinnerConfirm from "./nav/ConfirmWinner"
import WinnerAddress from "./nav/WinnerAddress"
import NewsletterConfirm from "./nav/NewsletterConfirm"
import Loosing from "./nav/Loosing"
const App = () => {
	//  Sun Apr 16 2023 00:00:00 UTC+0200 (Mitteleuropäische Sommerzeit)   https://time.is/de/Unix_time_converter
	const isPromoOver = parseInt(Math.floor(Date.now() / 1000).toString()) > 1721080800
	const isAgeAllowed = getCookie("radebergerger_u18")
	return (
		<BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
			<Store>
				<main className="bg-white">
					{!isAgeAllowed && <AgeCheckOverlay />}
					<Routes>
						{/* <Route path="/" element={<PreScreen />} /> */}

						{isPromoOver ? (
							<>
								<Route path="/" element={<Promoover />} />
								<Route path="/bestaetigung" element={<PreScreen />} />
							</>
						) : (
							<>
								<Route path="/" element={<Home />} />
								<Route path="/bestaetigung" element={<Confirm />} />
								<Route path="/abgelaufen" element={<Promoover />} />
								<Route path="/gewonnen" element={<WinnerAddress />} />
								<Route path="/schade" element={<Loosing />} />
								<Route path="/newsletter" element={<NewsletterConfirm />} />
								<Route path="/gewonnen" element={<WinnerConfirm />} />
								<Route path="/teilnehmen" element={<Teilnehmen />} />
								<Route path="/winner" element={<WinnerAdressForm />} />
								<Route path="/over" element={<Promoover />} />
							</>
						)}
						<Route path="/abgelaufen" element={<Promoover />} />
						<Route path="/pre" element={<PreScreen />} />
						<Route path="/faq" element={<FAQ />} />
						<Route path="/datenschutz" element={<Privacy />} />
						<Route path="/teilnahmebedingungen" element={<Terms />} />
						<Route path="/impressum" element={<Imprint />} />
						<Route path="/kontakt" element={<Contact />} />
						<Route path="/:err" element={<Error />} />
					</Routes>
				</main>
			</Store>
			<Footer />
		</BrowserRouter>
	)
}

export default App

import { getEnvConfig } from "../../env"
import axios from "axios"

export async function checkValidCodes(code) {
	const env = getEnvConfig()
	return await axios
		.get(env.apibase + "getValidCode.php?cv=" + Date.now() + "&code=" + code)
		.then(res => {
			return res
		})
		.catch(error => {
			return error.response.status
		})
}
